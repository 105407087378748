import "./chips.component.scss";
import React, { memo } from "react";
import { ChipsProps, ChipsClassNames, ChipsTheme } from "../../definitions";
import { getClassName } from "../../utils";

/**
 * Chips Component
 */
const Chips = <T extends {}>(props: ChipsProps<T>): JSX.Element => {
  const { className, id, dataId, theme, inline, clearIcon, items, onRemove } = props;

  const baseClassName = getClassName(ChipsClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${ChipsClassNames.Base}--${theme}` },
    { condition: inline, trueClassName: ChipsClassNames.BaseWithInlineModifier },
  ]);

  return (
    <ul className={baseClassName} id={id} data-id={dataId}>
      {(items || []).map(
        (item, index): JSX.Element => {
          const { key, className, id, dataId, label, value, data } = item;

          const itemClassName = getClassName(ChipsClassNames.Item, [{ condition: !!className, trueClassName: className }]);

          function handleRemove(): void {
            onRemove && onRemove(value, data);
          }

          return (
            <li key={key || `chips_item--${index}`} className={itemClassName} id={id} data-id={dataId}>
              <div className={ChipsClassNames.Label}>{label}</div>
              <i className={`${ChipsClassNames.Clear} ${clearIcon}`} onClick={handleRemove} />
            </li>
          );
        }
      )}
    </ul>
  );
};

Chips.defaultProps = {
  theme: ChipsTheme.SoftGrey,
  inline: true,
  clearIcon: "ni-close-4pt",
};

export default memo(Chips);

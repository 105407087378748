import "./scrollbars.component.scss";
import React from "react";
import { getClassName } from "../../utils";
import { ScrollbarsClassNames, ScrollbarsProps } from "./scrollbars.definition";
import { Scrollbars as ReactCustomScrollbars } from "react-custom-scrollbars";

const Scrollbars = <T extends {}>(props: ScrollbarsProps<T>): JSX.Element => {
  const {
    className,
    id,
    dataId,
    children,
    autoHeight,
    autoHide,
    darkMode,
    hideTracksWhenNotNeeded,
    showHorizontalTrack,
    scrollbarRef,
    onScroll,
  } = props;

  const baseClassName = getClassName(ScrollbarsClassNames.Base, [{ condition: !!className, trueClassName: className }]);

  function renderThumbOnWhite(): JSX.Element {
    return <div className={`${ScrollbarsClassNames.Thumb} ${ScrollbarsClassNames.ThumbWithWhiteModifier}`} />;
  }

  function renderLightThumb(): JSX.Element {
    return <div className={`${ScrollbarsClassNames.Thumb} ${ScrollbarsClassNames.ThumbWithLightModifier}`} />;
  }

  function renderDarkThumb(): JSX.Element {
    return <div className={`${ScrollbarsClassNames.Thumb} ${ScrollbarsClassNames.ThumbWithDarkModifier}`} />;
  }

  function renderTrackVertical(): JSX.Element {
    return <div className={ScrollbarsClassNames.Track} />;
  }

  function renderTrackHorizontal(): JSX.Element {
    return (
      <div
        className={getClassName(`${ScrollbarsClassNames.Track} ${ScrollbarsClassNames.TrackWithHorizontalModifier}`, [
          { condition: showHorizontalTrack, falseClassName: ScrollbarsClassNames.TrackWithHiddenModifier },
        ])}
      />
    );
  }

  const renderThumbVertical = typeof darkMode !== "boolean" ? renderThumbOnWhite : !darkMode ? renderLightThumb : renderDarkThumb;

  const scrollbarProps = {
    renderThumbVertical,
    renderTrackVertical,
    renderTrackHorizontal,
    autoHeight,
    autoHide,
    hideTracksWhenNotNeeded,
    onScroll,
  };

  return (
    <ReactCustomScrollbars {...scrollbarProps} className={baseClassName} id={id} data-id={dataId} ref={scrollbarRef}>
      {children}
    </ReactCustomScrollbars>
  );
};

Scrollbars.defaultProps = {
  autoHide: false,
  darkMode: false,
  hideTracksWhenNotNeeded: true,
  showHorizontalTrack: false,
};

export default Scrollbars;

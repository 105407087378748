import "./field.component.scss";
import React, { memo } from "react";
import { FieldProps, FieldClassNames, FormTheme } from "../../definitions";
import Collapsable from "../collapsable";
import { getClassName } from "../../utils";

const Field = (props: FieldProps): JSX.Element => {
  const { className, id, dataId, theme, children, label, labelHtmlFor, error } = props;

  const baseClassName = getClassName(FieldClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${FieldClassNames.Base}--${theme}` },
  ]);

  function renderChildren(): JSX.Element {
    if (!children) {
      return null;
    }

    return <div className={FieldClassNames.Children}>{children}</div>;
  }

  function renderError(): JSX.Element {
    return (
      <Collapsable collapsed={!error.visible}>
        <div className={FieldClassNames.Error}>
          <i className={`${FieldClassNames.ErrorIndicator} ${error.icon ?? "ni-warning-4pt"}`} />
          <span className={FieldClassNames.ErrorMessage}>{error.message}</span>
        </div>
      </Collapsable>
    );
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      {label ? (
        <label className={FieldClassNames.Label} htmlFor={labelHtmlFor}>
          <span className={FieldClassNames.LabelText}>{label}</span>
          {renderChildren()}
          {error && renderError()}
        </label>
      ) : (
        <>
          {renderChildren()}
          {error && renderError()}
        </>
      )}
    </div>
  );
};

Field.defaultProps = {
  theme: FormTheme.Light,
};

export default memo(Field);

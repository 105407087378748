import "./swapable.component.scss";
import React, { memo, useMemo, useRef, useState } from "react";
import { isNullOrWhiteSpace, isEmpty, getClassName } from "../../utils";
import { SwapableClassNames, SwapableProps } from "../../definitions";
import Ghostable from "../ghostable";

const Swapable = (props: SwapableProps): JSX.Element => {
  const { className, id, dataId, layers, selected } = props;
  const [height, setHeight] = useState(null);
  const swapableRef = useRef<HTMLDivElement>();

  const baseClassName = getClassName(SwapableClassNames.Base, [
    { condition: !isNullOrWhiteSpace(className), trueClassName: className },
    { condition: !isNullOrWhiteSpace(height), trueClassName: SwapableClassNames.BaseWithTransitionModifier },
  ]);

  // #region Lifecycle Methods
  function handleSelectedChange(): void {
    setHeight(swapableRef.current?.clientHeight);
  }

  useMemo(handleSelectedChange, [selected]);
  // #endregion

  // #region Handle Methods
  function handleEnter(node: HTMLElement): void {
    setHeight(node.clientHeight);
  }

  function handleEntered(): void {
    setHeight(null);

    const { onSwapEnd } = props;
    !isEmpty(onSwapEnd) && onSwapEnd();
  }

  function handleExiting(): void {
    const { onSwapStart } = props;
    !isEmpty(onSwapStart) && onSwapStart();
  }
  // #endregion

  const heightStyle = {
    height,
  };

  return (
    <div className={baseClassName} id={id} data-id={dataId} style={heightStyle} ref={swapableRef}>
      {layers.map(
        (layer, index): JSX.Element => {
          return (
            <Ghostable
              key={`swapable-component-${index}`}
              ghosted={selected !== index}
              onEnter={handleEnter}
              onEntered={handleEntered}
              onExiting={handleExiting}
            >
              <div className={SwapableClassNames.Layer}>{layer}</div>
            </Ghostable>
          );
        }
      )}
    </div>
  );
};

export default memo(Swapable);

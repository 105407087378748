import { FocusEvent, RefObject } from "react";

export default class FocusService {
  shouldBlur = (evt: FocusEvent, ...refs: (RefObject<HTMLElement> | HTMLElement)[]): Promise<boolean> => {
    return new Promise((resolve): void => {
      setTimeout((): void => {
        const relatedTarget = document.activeElement;

        resolve(this.isInComponent(relatedTarget, ...refs));
      }, 0);
    });
  };

  private isInComponent(relatedTarget: Node, ...refs: (RefObject<HTMLElement> | HTMLElement)[]): boolean {
    return refs.every((x): boolean => {
      const component = "current" in x ? x.current : x;
      return !component.contains(relatedTarget);
    });
  }
}

import { BaseComponentProps, Theme } from "../../definitions";
import { JustifyContentProperty } from "csstype";
import { ReactNode } from "react";

export interface ToolbarProps extends BaseComponentProps {
  /**
   * Used to paint the component using a specific theme
   */
  theme: ToolbarTheme;
  /**
   * Used to determine whether or not to wrap children within a ToolbarRow component
   */
  autoRow: boolean;
  /**
   * Used to supply the automatically included ToolbarRow component with props
   */
  autoRowProps?: ToolbarRowProps;
}

export enum ToolbarTheme {
  Ink = Theme.Ink,
  Q4Blue = Theme.Q4Blue,
  Steel = Theme.Steel,
  Rain = Theme.Rain,
  Slate = Theme.Slate,
}

export interface ToolbarRowProps extends BaseComponentProps {
  /**
   * Used to provide a variety of spacing for the row based on the CSS property type
   */
  justifyContent: JustifyContentProperty;
}

export interface ToolbarGroupProps extends BaseComponentProps {
  /**
   * Used to provide a variety of spacing for the group based on type
   */
  type: "default" | "text";
}

export interface ToolbarTitleProps extends BaseComponentProps {
  /**
   * Used to supply the component with content for the main heading
   */
  title: ReactNode;
  /**
   * Used to supply the component with content for the secondary heading
   */
  annotation?: ReactNode;
}

export enum ToolbarClassNames {
  Base = "nui-toolbar",
  Inner = "nui-toolbar_inner",
  Row = "nui-toolbar_row",
  Group = "nui-toolbar_group",
  Title = "nui-toolbar_title",
  TitleWithAnnotationModifier = "nui-toolbar_title--with-annotation",
  Annotation = "nui-toolbar_annotation",
}

import { ReactNode } from "react";
import { BaseComponentProps, ErrorModel, FormTheme } from "../../definitions";

export interface FieldProps extends BaseComponentProps {
  theme?: FormTheme;
  label?: ReactNode;
  labelHtmlFor?: string;
  error?: ErrorModel;
}

export enum FieldClassNames {
  Base = "nui-field",
  Label = "nui-field_label",
  LabelText = "nui-field_label-text",
  Children = "nui-field_children",
  Error = "nui-field_error",
  ErrorIndicator = "nui-field_error-indicator",
  ErrorMessage = "nui-field_error-message",
}

import React, { memo } from "react";
import { getClassName } from "../../../utils";
import { ToolbarClassNames, ToolbarGroupProps } from "../../../definitions";

function ToolbarGroup({ className, id, dataId, type, children }: ToolbarGroupProps): JSX.Element {
  const baseClassName = getClassName(ToolbarClassNames.Group, [
    { condition: !!className, trueClassName: className },
    { condition: !!type, trueClassName: `${ToolbarClassNames.Group}--${type}` },
  ]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      {children}
    </div>
  );
}

ToolbarGroup.defaultProps = {
  type: "default",
};

export default memo(ToolbarGroup);

import { BaseComponentProps, Theme, Opacity } from "../../definitions";

export enum SpinnerTheme {
  Rain = Theme.Rain,
  Citrus = Theme.Citrus,
  White = Theme.White,
}

export interface SpinnerProps extends Omit<BaseComponentProps, "children"> {
  /**
   * Used to paint the Button using a specific theme
   */
  theme?: SpinnerTheme;
  /**
   * Used to determine whether or not to paint the component with position: fixed styling
   */
  fixed?: boolean;
  /**
   * Used to determine whether or not to paint a mask along with the component
   */
  masked?: boolean;
  /**
   * Used to determine the opacity of the painted mask
   */
  maskOpacity?: Opacity;
}

export enum SpinnerClassNames {
  Base = "nui-spinner",
  BaseWithFixedModifier = "nui-spinner--fixed",
  BaseWithMaskedModifier = "nui-spinner--masked",
  Mask = "nui-spinner_mask",
  Pulse = "nui-spinner_pulse",
}

import { BaseComponentProps } from "../../definitions";

export interface GridProps extends BaseComponentProps {
  /**
   * Used to determine whether or not the grid will render a gutter between each column
   */
  gutter?: boolean;
}

export enum GridClassNames {
  Base = "nui-grid",
  BaseWithNoGutterModifier = "nui-grid--no-gutter",
  Column = "nui-grid_col",
  ColumnWithFirstModifier = "nui-grid_col--first",
  ColumnWithLastModifier = "nui-grid_col--last",
  ColumnWithCenteredModifier = "nui-grid_col--centered",
  ColumnWithNoMarginModifier = "nui-grid_col--no-margin",
}

import { AlignItemsProperty, FlexDirectionProperty, JustifyContentProperty } from "csstype";
import { BaseComponentProps, Theme } from "../../definitions";

export enum LayoutTheme {
  White = Theme.White,
  LightGrey = Theme.LightGrey,
  DarkSlate = Theme.DarkSlate,
  Transparent = Theme.Transparent,
}

export enum LayoutHeight {
  Auto = "auto",
  Comfy = "comfy",
  Full = "full",
  Viewport = "viewport",
}

export enum LayoutPadding {
  None = "none",
  Default = "default",
  Modal = "modal",
  Page = "page",
}

export interface LayoutProps extends BaseComponentProps {
  /**
   * The theme for the component
   */
  theme?: LayoutTheme;

  /**
   * The height of the component
   */
  height?: LayoutHeight;

  /**
   * Amount of padding surrounding the component in grid units (8px)
   */
  padding?: LayoutPadding;

  /**
   * Whether or not to use flex styles
   */
  flex?: boolean;

  /**
   * Horizontal alignment for children within the component
   */
  justifyContent?: JustifyContentProperty;

  /**
   * Vertical alignment for children within the component
   */
  alignItems?: AlignItemsProperty;

  /**
   * Vertical alignment for children within the component
   */
  direction?: FlexDirectionProperty;
}

export enum LayoutClassNames {
  Base = "nui-layout",
  BaseWithFlexModifier = "nui-layout--flex",
}

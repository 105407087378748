import { Theme, FieldProps, GridColumnProps, BaseComponentProps } from "../../definitions";

export enum FormTheme {
  Light = Theme.Light,
  Dark = Theme.Dark,
}

export interface FormFieldProps extends FieldProps, GridColumnProps {
  key: string;
}

export interface FormProps extends Omit<BaseComponentProps, "children"> {
  theme?: FormTheme;
  fields: FormFieldProps[];
}

export enum FormClassNames {
  Base = "nui-form",
}

export enum FormIds {
  GridColumn = "GridColumn",
  Field = "Field",
}

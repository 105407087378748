import { MouseEvent } from "react";
import { BaseComponentProps, Target, Theme } from "../../definitions";

export enum ButtonSize {
  Small = "small",
  Default = "default",
  Large = "large",
}

export enum ButtonTheme {
  Citrus = Theme.Citrus,
  Rain = Theme.Rain,
  DarkSlate = Theme.DarkSlate,
  LightGrey = Theme.LightGrey,
  Spice = Theme.Spice,
  Teal = Theme.Teal,
  Steel = Theme.Steel,
  Ink = Theme.Ink,
  Transparent = Theme.Transparent,
  Q4Blue = Theme.Q4Blue,
  Cherry = Theme.Cherry,
}

export interface ButtonStyles {
  base: {};
  icon: {};
  label: {};
}

export interface ButtonProps extends BaseComponentProps {
  /**
   * Used to paint the Button using a specific theme
   */
  theme?: ButtonTheme;

  /**
   * Custom styles passed into the component
   */
  styles?: ButtonStyles;

  /**
   * Used to provide the button with a label
   */
  label?: string | JSX.Element;

  /**
   * Used to provide the button with an icon
   */
  icon?: string | JSX.Element;

  /**
   * Used to transform the button into a Link component
   * Note: prop provides link url
   */
  linkTo?: string;

  /**
   * Add a target to a link
   */
  linkTarget?: Target;

  /**
   * Used to modify the Button component with various modifiers
   */
  size?: ButtonSize;
  square?: boolean;
  circle?: boolean;
  wide?: boolean;

  /**
   * Used to paint the Button component with a pulsing loader
   */
  loading?: boolean;

  /**
   * Used to hide the Button component with opacity
   */
  invisible?: boolean;

  /**
   * Used to hide the Button component with display: none
   */
  hidden?: boolean;

  /**
   * Used to disable interaction with the Button component
   */
  disabled?: boolean;

  /**
   * A callback for when the user clicks the Button component
   */
  onClick?: (event: MouseEvent) => void;
}

export enum ButtonClassNames {
  Base = "nui-button",
  BaseWithLinkToModifier = "nui-button--link",
  BaseWithWideModifier = "nui-button--wide",
  BaseWithCircleModifier = "nui-button--circle",
  BaseWithSquareModifier = "nui-button--square",
  BaseWithLoadingModifier = "nui-button--loading",
  BaseWithInvisibleModifier = "nui-button--invisible",
  BaseWithHiddenModifier = "nui-button--hidden",
  BaseWithDisabledModifier = "nui-button--disabled",
  Icon = "nui-button_icon",
  Label = "nui-button_label",
}

import { BaseComponentProps } from "../../definitions";

export interface CollapsableState {
  currentHeight: number;
  isCollapsing: boolean;
  isVisible: boolean;
}

export interface CollapsableProps extends BaseComponentProps {
  collapsed: boolean;
  onExpand?: Function;
  onCollapse?: Function;
}

export enum CollapsableClassNames {
  Base = "nui-collapsable",
  BaseWithCollapsingModifier = "nui-collapsable--collapsing",
  BaseWithCollapsedModifier = "nui-collapsable--collapsed",
  Content = "nui-collapsable_content",
  OpacityLayer = "nui-collapsable_opacity-layer",
}

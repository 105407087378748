import "./button.component.scss";
import React, { memo, MouseEvent } from "react";
import { getClassName } from "../../utils";
import { ButtonClassNames, ButtonProps } from "./button.definition";

const Button = (props: ButtonProps): JSX.Element => {
  const {
    className,
    id,
    linkTo,
    linkTarget,
    styles,
    label,
    icon,
    theme,
    size,
    wide,
    circle,
    square,
    loading,
    invisible,
    hidden,
    disabled,
    onClick,
  } = props;

  const baseClassName = getClassName(ButtonClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${ButtonClassNames.Base}--${theme}` },
    { condition: !!size, trueClassName: `${ButtonClassNames.Base}--${size}` },
    { condition: !!linkTo, trueClassName: ButtonClassNames.BaseWithLinkToModifier },
    { condition: wide, trueClassName: ButtonClassNames.BaseWithWideModifier },
    { condition: circle, trueClassName: ButtonClassNames.BaseWithCircleModifier },
    { condition: !!(square || (!label && icon)), trueClassName: ButtonClassNames.BaseWithSquareModifier },
    { condition: loading, trueClassName: ButtonClassNames.BaseWithLoadingModifier },
    { condition: invisible, trueClassName: ButtonClassNames.BaseWithInvisibleModifier },
    { condition: hidden, trueClassName: ButtonClassNames.BaseWithHiddenModifier },
    { condition: disabled, trueClassName: ButtonClassNames.BaseWithDisabledModifier },
  ]);

  function handleClick(event: MouseEvent): void {
    onClick && onClick(event);
  }

  return linkTo ? (
    <a className={baseClassName} id={id} href={linkTo} target={linkTarget} style={styles && styles.base} onClick={handleClick}>
      {icon && <i className={`${ButtonClassNames.Icon} ${icon}`} style={styles && styles.icon} />}
      {label && (
        <span className={ButtonClassNames.Label} style={styles && styles.label}>
          {label}
        </span>
      )}
    </a>
  ) : (
    <button className={baseClassName} id={id} style={styles && styles.base} onClick={handleClick}>
      {icon && <i className={`${ButtonClassNames.Icon} ${icon}`} style={styles && styles.icon} />}
      {label && (
        <span className={ButtonClassNames.Label} style={styles && styles.label}>
          {label}
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  linkTo: null,
};

export default memo(Button);

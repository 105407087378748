import "./banner.component.scss";
import React, { memo } from "react";
import { BannerClassNames, BannerControl, BannerControlType, BannerProps, BannerSize, ButtonProps, ButtonSize } from "../../definitions";
import { getClassName } from "../../utils";
import Button from "../button/";

/**
 * Banner Component
 */
function Banner(props: BannerProps): JSX.Element {
  const {
    className,
    id,
    dataId,
    size,
    theme,
    badgeTheme,
    badgeIcon,
    title,
    details,
    controls,
    children,
    alignChildrenWithBadge,
    backIcon,
    onBackRequest,
  } = props;

  const baseClassName = getClassName(BannerClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${BannerClassNames.Base}--${theme}` },
    { condition: !!size, trueClassName: `${BannerClassNames.Base}--${size}` },
    { condition: !!details, trueClassName: BannerClassNames.BaseWithDetailsModifier },
    { condition: !!onBackRequest, trueClassName: BannerClassNames.BaseWithBackRequestModifier },
  ]);
  const badgeClassName = getClassName(BannerClassNames.Badge, [
    { condition: !!badgeTheme, trueClassName: `${BannerClassNames.Badge}--${badgeTheme}` },
  ]);
  const childrenClassName = getClassName(BannerClassNames.Children, [
    { condition: alignChildrenWithBadge, trueClassName: BannerClassNames.ChildrenWithBadgeAlignModifier },
  ]);

  /**
   * Render Banner control sub-component
   * @param control
   * @param index
   * @returns {null|*}
   */
  function renderControl(control: BannerControl, index: number): JSX.Element {
    switch (control.type) {
      case BannerControlType.Button:
        return renderBannerButton(control.props, index);
      // case 'badge':
      //   return <Badge key={index} {...control} />
      default:
        return null;
    }
  }

  /**
   * Render Banner Button
   * @param props
   * @param index
   */
  function renderBannerButton(props: ButtonProps, index: number): JSX.Element {
    return <Button {...props} key={`banner-button--${index}`} size={size === BannerSize.Thin ? ButtonSize.Large : ButtonSize.Default} />;
  }

  return (
    <header className={baseClassName} id={id} data-id={dataId}>
      <div className={BannerClassNames.Inner}>
        <div className={BannerClassNames.Elements}>
          <div className={BannerClassNames.Header}>
            {badgeIcon && <i className={`${badgeClassName} ${badgeIcon}`} />}
            <div className={BannerClassNames.Information}>
              {onBackRequest && (
                <div className={BannerClassNames.Back} onClick={onBackRequest}>
                  <i className={backIcon} /> Back
                </div>
              )}
              <h1 className={BannerClassNames.Title}>{title}</h1>
              {details && <div className={BannerClassNames.Details}>{details}</div>}
            </div>
          </div>
          <div className={BannerClassNames.Controls}>{(controls || []).map((control: BannerControl, index) => renderControl(control, index))}</div>
        </div>
        {children && <div className={childrenClassName}>{children}</div>}
      </div>
    </header>
  );
}

Banner.defaultProps = {
  size: BannerSize.Default,
  controls: [],
  backIcon: "ni-arrow-left-4pt",
  alignChildrenWithBadge: false,
};

export default memo(Banner);

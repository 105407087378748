import React, { memo } from "react";
import { GridClassNames, GridColumnProps } from "../../../definitions";
import { getClassName } from "../../../utils";

function GridColumn(props: GridColumnProps): JSX.Element {
  const { className, id, dataId, children, width, smallWidth, mobileWidth, first, last, centered, margin, verticalAlign } = props;

  const baseClassName = getClassName(GridClassNames.Column, [
    { condition: !!className, trueClassName: className },
    { condition: !!width, trueClassName: `${GridClassNames.Column}--${width}` },
    { condition: !!smallWidth, trueClassName: `${GridClassNames.Column}--small-${smallWidth}` },
    { condition: !!mobileWidth, trueClassName: `${GridClassNames.Column}--mobile-${mobileWidth}` },
    { condition: first, trueClassName: GridClassNames.ColumnWithFirstModifier },
    { condition: last, trueClassName: GridClassNames.ColumnWithLastModifier },
    { condition: centered, trueClassName: GridClassNames.ColumnWithCenteredModifier },
    { condition: margin, falseClassName: GridClassNames.ColumnWithNoMarginModifier },
    { condition: verticalAlign && verticalAlign !== "top", trueClassName: `${GridClassNames.Column}--${verticalAlign}` },
  ]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      {children}
    </div>
  );
}

GridColumn.defaultProps = {
  first: false,
  last: false,
  centered: false,
  margin: true,
  verticalAlign: "top",
};

export default memo(GridColumn);

// #region Imports
import "./focusInOut.component.scss";
import React, { FocusEvent, MouseEvent, useEffect, useRef, useState } from "react";
import FocusService from "../../services/focus/focus.service";
import { FocusInOutProps } from "./focusInOut.definition";
import { isEmpty, isNullOrWhiteSpace, getClassName } from "../../utils";
// #endregion

const FocusInOut = (props: FocusInOutProps): JSX.Element => {
  const [hasFocus, setHasFocus] = useState(false);
  const focusService = useRef(new FocusService());
  const scrollContainer = useRef<Element>();
  const { className, children, componentRef, tabIndex, onFocus, onBlur, parentComponentRef, scrollContainerClass } = props;

  // #region Helper Methods
  function forceBlur(): void {
    setHasFocus(false);
    (document.activeElement as HTMLElement).blur();
  }
  // #endregion

  // #region Handle Methods
  function handleFocus(evt: FocusEvent<HTMLDivElement>): void {
    setHasFocus(true);
    !isEmpty(onFocus) && onFocus(evt);
  }

  function handleClick(evt: MouseEvent<HTMLDivElement>): void {
    // stop click event from bubbling up past the focusInOut component
    // this will stop nested components from blurring
    evt.stopPropagation();
  }

  function handleBlur(evt: FocusEvent): void {
    if (isEmpty(evt)) return;

    const refs = isEmpty(parentComponentRef) ? [componentRef] : [parentComponentRef, componentRef];
    focusService.current.shouldBlur(evt, ...refs).then((shouldBlur): void => {
      if (shouldBlur) {
        setHasFocus(false);
        !isEmpty(onBlur) && onBlur();
      }
    });
  }

  function handleScroll(): void {
    forceBlur();
    !isEmpty(onBlur) && onBlur();
  }
  // #endregion

  // #region Lifecycle Methods
  function handleEventListeners(): () => void {
    const scrollEl = document.querySelector(scrollContainerClass);
    if (!hasFocus || isEmpty(scrollEl)) return;
    scrollContainer.current = scrollEl.parentElement;
    scrollContainer.current.addEventListener("scroll", handleScroll);

    return (): void => {
      if (!hasFocus || isEmpty(scrollContainer.current)) return;
      scrollContainer.current.removeEventListener("scroll", handleScroll);
    };
  }
  useEffect(handleEventListeners, [hasFocus]);
  // #endregion

  return (
    <div
      className={getClassName("nui-focusInOut", [{ condition: !isNullOrWhiteSpace(className), trueClassName: className }])}
      onFocus={handleFocus}
      onClick={handleClick}
      onBlur={handleBlur}
      ref={componentRef}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
};

FocusInOut.defaultProps = {
  tabIndex: -1,
};

export default FocusInOut;

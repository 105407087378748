import { ChangeEvent, CSSProperties, RefObject } from "react";
import { BaseComponentProps, Theme } from "../../definitions";

export enum TextboxTheme {
  Dark = Theme.Dark,
  LightGrey = Theme.LightGrey,
  Rain = Theme.Rain,
  Ink = Theme.Ink,
  Spice = Theme.Spice,
  Slate = Theme.Slate,
  White = Theme.White,
}

export enum TextboxSize {
  Small = "small",
  Default = "default",
  Large = "large",
}

export interface TextboxProps extends BaseComponentProps {
  inputId?: string;
  inputRef?: RefObject<HTMLInputElement>;
  theme?: TextboxTheme;
  size?: TextboxSize;
  style?: CSSProperties;
  maxLength?: number;
  value: string | number;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onBlur?: Function;
  onClick?: Function;
  onFocus?: Function;
  onKeyDown?: Function;
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
}

export enum TextboxClassNames {
  Base = "nui-textbox",
  BaseWithReadOnlyModifier = "nui-textbox--read-only",
  BaseWithDisabledModifier = "nui-textbox--disabled",
  Input = "nui-textbox_input",
  Focus = "nui-textbox_focus",
}

export enum TextboxIds {
  Input = "Input",
}

import "./placeholderContent.component.scss";
import React, { memo } from "react";
import {
  ButtonProps,
  LayoutHeight,
  LayoutTheme,
  PlaceholderContentProps,
  PlaceholderContentTheme,
  PlaceholderContentClassNames,
} from "../../definitions";
import { getClassName } from "../../utils";
import Button from "../button";
import Layout from "../layout";

const PlaceholderContent = (props: PlaceholderContentProps): JSX.Element => {
  const { id, dataId, className, theme, layoutProps, icon, image, title, subtitle, actions, caption } = props;

  const baseClassName = getClassName(PlaceholderContentClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${PlaceholderContentClassNames.Base}--${theme}` },
  ]);

  function renderActionButton(buttonProps: ButtonProps, index: number): JSX.Element {
    return <Button {...buttonProps} key={`nui-placeholder-content_button-${index}`} />;
  }

  return (
    <Layout flex theme={LayoutTheme.Transparent} height={LayoutHeight.Full} {...layoutProps} id={id} dataId={dataId} className={baseClassName}>
      <div className={PlaceholderContentClassNames.Inner}>
        {icon && <i className={`${PlaceholderContentClassNames.Icon} ${icon}`} />}
        {image && <img className={PlaceholderContentClassNames.Image} src={image} alt={title} />}
        <h2 className={PlaceholderContentClassNames.Title}>{title}</h2>
        {subtitle && <h3 className={PlaceholderContentClassNames.Subtitle}>{subtitle}</h3>}
        {actions?.length > 0 && <div className={PlaceholderContentClassNames.Actions}>{(actions || []).map(renderActionButton)}</div>}
        {caption && <h4 className={PlaceholderContentClassNames.Caption}>{caption}</h4>}
      </div>
    </Layout>
  );
};

PlaceholderContent.defaultProps = {
  theme: PlaceholderContentTheme.Transparent,
};

export default memo(PlaceholderContent);

import "./popover.component.scss";
import React, { memo } from "react";
import { getClassName } from "../../utils";
import Portal from "../portal";
import { Origin, PopoverClassNames } from "../../definitions";
import { PopoverProps, AnchorPositionProperties, TargetBoundRect, PopoverPositionProperties, PopoverStyles } from "./popover.definition";

function Popover(props: PopoverProps): JSX.Element {
  const {
    className,
    children,
    anchorTargetElementId,
    anchorTargetReference,
    popoverOrigin,
    targetOrigin,
    offsetMargin,
    matchWidth,
    matchHeight,
    ...portalProps
  } = props;

  const baseClassName = getClassName(PopoverClassNames.Base, [{ condition: !!className, trueClassName: className }]);

  /**
   * Get Target bounding rect props for popover positioning
   */
  function getTargetBoundingRect(): TargetBoundRect {
    if (!anchorTargetReference && !anchorTargetElementId) {
      return null;
    }

    if (anchorTargetReference && anchorTargetReference.current) {
      const targetComponentRect = anchorTargetReference.current.getBoundingClientRect();
      const { top, left, width, height } = targetComponentRect;

      return { top, left, width, height };
    }

    if (anchorTargetElementId) {
      const target = document.getElementById(anchorTargetElementId);

      if (!target || !target.getBoundingClientRect) {
        console.warn(
          `Popover component is unable to find target element or access a boundingClientRect with the currently proposed anchorTargetElementId prop, ${anchorTargetElementId}.`
        );
        return null;
      }

      const { top, left, width, height } = target.getBoundingClientRect();

      return { top, left, width, height };
    }
  }

  /**
   * Get Target's coordinates based on origin prop
   */
  function getAnchorPositionProperties({ left, top, width, height }: TargetBoundRect): AnchorPositionProperties {
    const coordinates: AnchorPositionProperties = {
      left: 0,
      top: 0,
    };

    switch (targetOrigin) {
      case Origin.TopLeft:
        coordinates.left = left;
        coordinates.top = top;
        break;
      case Origin.Top:
      case Origin.TopCenter:
        coordinates.left = left + width / 2;
        coordinates.top = top;
        break;
      case Origin.TopRight:
        coordinates.left = left + width;
        coordinates.top = top;
        break;
      case Origin.Left:
      case Origin.CenterLeft:
        coordinates.left = left;
        coordinates.top = top + height / 2;
        break;
      case Origin.Center:
        coordinates.left = left + width / 2;
        coordinates.top = top + height / 2;
        break;
      case Origin.Right:
      case Origin.CenterRight:
        coordinates.left = left + width;
        coordinates.top = top + height / 2;
        break;
      case Origin.BottomLeft:
        coordinates.left = left;
        coordinates.top = top + height;
        break;
      case Origin.Bottom:
      case Origin.BottomCenter:
        coordinates.left = left + width / 2;
        coordinates.top = top + height;
        break;
      case Origin.BottomRight:
      default:
        coordinates.left = left + width;
        coordinates.top = top + height;
        break;
    }

    return coordinates;
  }

  /**
   * Get Popover Position CSS Properties based on origin prop
   */
  function getPopoverPositionProperties(): Partial<PopoverPositionProperties> {
    const properties: Partial<PopoverPositionProperties> = {
      horizontal: "left",
      vertical: "top",
    };

    switch (popoverOrigin) {
      case Origin.TopLeft:
      default:
        properties.horizontal = "left";
        properties.vertical = "top";
        break;
      case Origin.Top:
      case Origin.TopCenter:
        properties.horizontal = "left";
        properties.vertical = "top";
        properties.transform = "translateX(-50%)";
        break;
      case Origin.TopRight:
        properties.horizontal = "right";
        properties.vertical = "top";
        break;
      case Origin.Left:
      case Origin.CenterLeft:
        properties.horizontal = "left";
        properties.vertical = "top";
        properties.transform = "translateY(-50%)";
        break;
      case Origin.Center:
        properties.horizontal = "left";
        properties.vertical = "top";
        properties.transform = "translate(-50%, -50%)";
        break;
      case Origin.Right:
      case Origin.CenterRight:
        properties.horizontal = "right";
        properties.vertical = "top";
        properties.transform = "translateY(-50%)";
        break;
      case Origin.BottomLeft:
        properties.horizontal = "left";
        properties.vertical = "bottom";
        break;
      case Origin.Bottom:
      case Origin.BottomCenter:
        properties.horizontal = "left";
        properties.vertical = "bottom";
        properties.transform = "translateX(-50%)";
        break;
      case Origin.BottomRight:
        properties.horizontal = "right";
        properties.vertical = "bottom";
        break;
    }

    return properties;
  }

  /**
   * Get Popover styles
   */
  function getStyles(): PopoverStyles {
    const targetBoundingRect = getTargetBoundingRect();

    if (!targetBoundingRect) {
      return null;
    }

    const anchorPositionProperties = getAnchorPositionProperties(targetBoundingRect);
    const popoverPositionProperties = getPopoverPositionProperties();

    return {
      anchor: {
        ...anchorPositionProperties,
        transformOrigin: popoverOrigin ? popoverOrigin.replace("-", " ") : null,
      },
      inner: {
        [popoverPositionProperties.horizontal]: 0,
        [popoverPositionProperties.vertical]: 0,
        width: matchWidth ? targetBoundingRect.width : null,
        height: matchHeight ? targetBoundingRect.height : null,
        margin: offsetMargin,
        transform: popoverPositionProperties.transform,
      },
    };
  }

  const baseStyles = getStyles();

  if (!baseStyles) {
    return null;
  }

  return (
    <Portal className={baseClassName} {...portalProps}>
      <div className={PopoverClassNames.Anchor} style={baseStyles.anchor}>
        <div className={PopoverClassNames.Inner} style={baseStyles.inner}>
          {children}
        </div>
      </div>
    </Portal>
  );
}

Popover.defaultProps = {
  targetOrigin: Origin.TopRight,
  popoverOrigin: Origin.TopLeft,
  position: "fixed",
};

export default memo(Popover);

import { ReactNode } from "react";
import { BaseComponentProps, Theme } from "../../definitions";

export enum ChipsTheme {
  White = Theme.White,
  SoftGrey = Theme.SoftGrey,
  Rain = Theme.Rain,
  Citrus = Theme.Citrus,
}

export interface ChipProps<T> extends Omit<BaseComponentProps, "children"> {
  /**
   * Used to provide label text for the individual chip
   */
  label: ReactNode;
  /**
   * Used to provide a value for the individual chip
   * This value will be passed as the first argument when attempting to remove the chip
   */
  value: string;
  /**
   * Used to provide additional data for the individual chip
   * This value will be passed as the second argument when attempting to remove the chip
   */
  data?: T;
}

export interface ChipsProps<T> extends Omit<BaseComponentProps, "children"> {
  /**
   * Used to paint the component using a specific theme
   */
  theme?: ChipsTheme;
  /**
   * Used to supply the component with items
   */
  items: ChipProps<T>[];
  /**
   * Used to determine whether or not the chips flow inline
   */
  inline?: boolean;
  /**
   * Used to supply the component with a clear icon
   */
  clearIcon?: string;
  /**
   * Used to populate a callback function for when a user attempts to remove a chip
   */
  onRemove: (value: string, data: T) => void;
}

export enum ChipsClassNames {
  Base = "nui-chips",
  BaseWithInlineModifier = "nui-chips--inline",
  Item = "nui-chips_item",
  Label = "nui-chips_label",
  Clear = "nui-chips_clear",
}

export const Namespace = "nui-";
export const GridUnit = 8;
export const DateFormat = "MM/DD/YYYY";
export const DebounceDelay = 400;
export const StringMaxLength = 255;

export interface ConditionalClassName {
  condition: boolean;
  trueClassName?: string;
  falseClassName?: string;
}

export enum Theme {
  Apple = "apple",
  Ash = "ash",
  Charcoal = "charcoal",
  Cherry = "cherry",
  Citrus = "citrus",
  Dark = "dark",
  DarkSlate = "dark-slate",
  Eggplant = "eggplant",
  Gunmetal = "gunmetal",
  Ink = "ink",
  Light = "light",
  LightGrey = "light-grey",
  LightSlate = "light-slate",
  Mustard = "mustard",
  None = "none",
  PaleGrey = "pale-grey",
  Q4Blue = "q4-blue",
  Rain = "rain",
  Secondary = "secondary",
  Silver = "silver",
  Slate = "slate",
  BlackSmoke = "black-smoke",
  SoftGrey = "soft-grey",
  Spice = "spice",
  Steel = "steel",
  Surf = "surf",
  Tangerine = "tangerine",
  Teal = "teal",
  Transparent = "transparent",
  White = "white",
}

export enum Origin {
  TopLeft = "top-left",
  Top = "top",
  TopCenter = "top-center",
  TopRight = "top-right",
  Left = "left",
  CenterLeft = "center-left",
  Center = "center",
  Right = "right",
  CenterRight = "center-right",
  BottomLeft = "bottom-left",
  Bottom = "bottom",
  BottomCenter = "bottom-center",
  BottomRight = "bottom-right",
}

export enum VerticalAlign {
  Baseline = "baseline",
  Sub = "sub",
  Super = "super",
  Top = "top",
  TextTop = "text-top",
  Middle = "middle",
  Bottom = "bottom",
  TextBottom = "text-bottom",
}

export enum Target {
  Self = "_self",
  Top = "_top",
  Blank = "_blank",
  Parent = "_parent",
}

export const AnimateTime = 300;

export enum TransitionSpeedModifier {
  Fast = "fast",
  Default = "default",
  Slow = "slow",
}

export class TransitionSpeed {
  private readonly _modifier: TransitionSpeedModifier;

  constructor(modifier: TransitionSpeedModifier) {
    this._modifier = modifier;
  }

  get modifier(): string {
    return this._modifier;
  }

  get time(): number {
    switch (this._modifier) {
      case TransitionSpeedModifier.Fast:
        return AnimateTime / 2;
      case TransitionSpeedModifier.Default:
        return AnimateTime;
      case TransitionSpeedModifier.Slow:
        return AnimateTime * 20;
    }
  }
}

export enum Opacity {
  Full = "full",
  High = "high",
  Medium = "medium",
  Low = "low",
  Subtle = "subtle",
  Transparent = "transparent",
}

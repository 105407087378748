import { MouseEvent, ReactNode } from "react";
import { BaseComponentProps, ButtonProps, Theme } from "../../definitions";

export enum BannerSize {
  Thin = "thin",
  Medium = "medium",
  Default = "default",
}

export enum BannerTheme {
  Slate = Theme.Slate,
  White = Theme.White,
}

export enum BannerBadgeTheme {
  DarkSlate = Theme.DarkSlate,
  Teal = Theme.Teal,
  Spice = Theme.Spice,
}

export enum BannerControlType {
  Button = "button",
}

export interface BannerControl extends BaseComponentProps {
  type: BannerControlType;
  props: ButtonProps;
}

export interface BannerProps extends BaseComponentProps {
  /**
   * Used to paint the component using a specific theme
   */
  theme?: BannerTheme;
  /**
   * Used to indicate the size of the banner
   */
  size?: BannerSize;
  /**
   * The icon class used to power the Banner component's badge
   */
  badgeIcon: string;
  /**
   * Used to paint the Button using a specific theme
   */
  badgeTheme?: BannerBadgeTheme;
  /**
   * The title to display in the Banner component
   */
  title: ReactNode;
  /**
   * Used to provide additional details below the Banner component's title
   */
  details?: ReactNode;
  /**
   * An array of controls component to render within the Banner component
   */
  controls?: BannerControl[];
  /**
   * Used to determine whether or not the Banner component's children elements will be aligned with the badge
   */
  alignChildrenWithBadge?: boolean;
  /**
   * Used to provide a callback for when the user clicks a provided back button
   * Note: Use of this prop will render the back button
   */
  onBackRequest?: (e: MouseEvent) => void;
  /*
   * An icon name for the back icon
   */
  backIcon?: string;
}

export enum BannerClassNames {
  Base = "nui-banner",
  BaseWithDetailsModifier = "nui-banner--detailed",
  BaseWithBackRequestModifier = "nui-banner--with-back",
  Inner = "nui-banner_inner",
  Elements = "nui-banner_elements",
  Header = "nui-banner_header",
  Information = "nui-banner_information",
  Badge = "nui-banner_badge",
  Back = "nui-banner_back",
  Title = "nui-banner_title",
  Details = "nui-banner_details",
  Controls = "nui-banner_controls",
  Children = "nui-banner_children",
  ChildrenWithBadgeAlignModifier = "nui-banner_children--badge-aligned",
}

import "./textbox.component.scss";
import React, { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent } from "react";
import { TextboxClassNames, TextboxIds, TextboxProps, TextboxSize, TextboxTheme } from "./textbox.definition";
import { getClassName, getId, isEmpty } from "../../utils";
import { StringMaxLength } from "../../definitions";

const Textbox = (props: TextboxProps): JSX.Element => {
  const {
    id,
    dataId,
    inputId,
    inputRef,
    className,
    maxLength,
    placeholder,
    size,
    theme,
    value,
    readOnly,
    disabled,
    autoFocus,
    onChange,
    onBlur,
    onFocus,
    onClick,
    onKeyDown,
  } = props;

  const baseClassName = getClassName(TextboxClassNames.Base, [
    { condition: !!className, trueClassName: className },
    {
      condition: !!theme,
      trueClassName: `${TextboxClassNames.Base}--${theme}`,
      falseClassName: `${TextboxClassNames.Base}--${Textbox.defaultProps.theme}`,
    },
    { condition: !!size, trueClassName: `${TextboxClassNames.Base}--${size}` },
    { condition: readOnly, trueClassName: TextboxClassNames.BaseWithReadOnlyModifier },
    { condition: disabled, trueClassName: TextboxClassNames.BaseWithDisabledModifier },
  ]);

  function handleChange(evt: ChangeEvent<HTMLInputElement>): void {
    onChange(evt.target.value, evt);
  }

  function handleBlur(evt: FocusEvent<HTMLInputElement>): void {
    !isEmpty(onBlur) && onBlur(evt);
  }

  function handleFocus(evt: FocusEvent): void {
    !isEmpty(onFocus) && onFocus(evt);
  }

  function handleClick(evt: MouseEvent): void {
    !isEmpty(onClick) && onClick(evt);
  }

  function handleKeyDown(evt: KeyboardEvent): void {
    !isEmpty(onKeyDown) && onKeyDown(evt);
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <input
        ref={inputRef}
        id={!!inputId ? inputId : getId(id, TextboxIds.Input)}
        className={TextboxClassNames.Input}
        type="text"
        value={value || ""}
        maxLength={maxLength}
        placeholder={placeholder}
        autoFocus={autoFocus}
        readOnly={readOnly}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!readOnly && !disabled && <span className={TextboxClassNames.Focus} />}
    </div>
  );
};

Textbox.defaultProps = {
  theme: TextboxTheme.LightGrey,
  size: TextboxSize.Default,
  placeholder: "",
  maxLength: StringMaxLength,
};

export default Textbox;

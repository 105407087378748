import "./spinner.component.scss";
import React, { memo } from "react";
import { SpinnerClassNames, SpinnerProps, SpinnerTheme } from "./spinner.definition";
import { getClassName } from "../../utils/ui";
import { Opacity } from "../../definitions";

/**
 * Spinner Component
 */
function Spinner(props: SpinnerProps): JSX.Element {
  const { className, id, dataId, fixed, theme, masked, maskOpacity } = props;

  const baseClassName = getClassName(SpinnerClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${SpinnerClassNames.Base}--${theme}` },
    { condition: fixed, trueClassName: SpinnerClassNames.BaseWithFixedModifier },
    { condition: masked, trueClassName: SpinnerClassNames.BaseWithMaskedModifier },
  ]);
  const maskClassName = getClassName(SpinnerClassNames.Mask, [
    { condition: !!maskOpacity, trueClassName: `${SpinnerClassNames.Mask}--${maskOpacity}` },
  ]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <div className={SpinnerClassNames.Pulse} />
      {masked && <div className={maskClassName} />}
    </div>
  );
}

Spinner.defaultProps = {
  theme: SpinnerTheme.Rain,
  fixed: false,
  masked: true,
  maskOpacity: Opacity.Subtle,
};

export default memo(Spinner);

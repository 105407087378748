import "./layout.component.scss";
import React, { memo } from "react";
import { getClassName } from "../../utils";
import { LayoutProps, LayoutPadding, LayoutHeight, LayoutTheme, LayoutClassNames } from "./layout.definition";

/**
 * Layout Component
 */
function Layout(props: LayoutProps): JSX.Element {
  const { children, className, id, dataId, theme, height, padding, flex, justifyContent, alignItems, direction } = props;

  const baseClassName = getClassName(LayoutClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${LayoutClassNames.Base}--${theme}` },
    { condition: !!height, trueClassName: `${LayoutClassNames.Base}--height-${height}` },
    { condition: !!padding, trueClassName: `${LayoutClassNames.Base}--padding-${padding}` },
    { condition: flex, trueClassName: LayoutClassNames.BaseWithFlexModifier },
    {
      condition: flex && !!justifyContent,
      trueClassName: `${LayoutClassNames.Base}--justify-${justifyContent}`,
    },
    {
      condition: flex && !!alignItems,
      trueClassName: `${LayoutClassNames.Base}--align-${alignItems}`,
    },
    {
      condition: flex && !!direction,
      trueClassName: `${LayoutClassNames.Base}--direction-${direction}`,
    },
  ]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      {children}
    </div>
  );
}

Layout.defaultProps = {
  theme: LayoutTheme.White,
  height: LayoutHeight.Auto,
  padding: LayoutPadding.Default,
  flex: true,
  justifyContent: "center",
  alignItems: "center",
};

export default memo(Layout);

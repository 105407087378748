import { ReactNode } from "react";
import { ButtonProps, PortalProps } from "../../definitions";

export interface ModalProps extends PortalProps {
  /**
   * Used to determine whether or not to render a Spinner component within the Modal component
   * loadingProps can be provided to customize the Spinner component
   */
  loading?: boolean;
  spinnerProps?: {};

  /**
   * Used to supply the title with text to paint into the Modal Component
   */
  title: ReactNode;

  /**
   * Used to supply the subtitle with text to paint into the Modal Component
   */
  subtitle?: ReactNode;

  /**
   * Use to supply the badge with an icon to paint into the Modal Component before the header title
   */
  badgeIcon?: string;

  /**
   * Used to determine whether or not to paint an closing-X icon into the Modal component's header
   */
  includeExit?: boolean;

  /**
   * Used to determine the exit icon's className
   */
  exitIcon?: string;

  /**
   * Used to determine the size (in pixels) of the content within the Modal component's body
   */
  contentHeight?: number;

  /**
   * Used to supply the header or footer with appropriate actions within the Modal component
   * Note: Refer to the Button component for prop definitions
   */
  headerActions?: ButtonProps[];
  footerActions?: ButtonProps[];

  /**
   * Used to determine whether or not to paint a small chin into the Modal component's footer
   * Note: This is enabled by the default when the footerActions are not present
   */
  includeChin?: boolean;

  /**
   * Used to determine whether or not to paint the Modal component in full-screen
   */
  fullscreen?: boolean;

  /**
   * Used to determine whether or not to wrap the Modal component's body content in a Scrollbars component
   */
  scrollable?: boolean;
}

export enum ModalClassNames {
  Base = "nui-modal",
  BaseWithLoadingModifier = "nui-modal--loading",
  BaseWithScrollableModifier = "nui-modal--scrollable",
  BaseWithFullscreenModifier = "nui-modal--fullscreen",
  Container = "nui-modal_container",
  Header = "nui-modal_header",
  TitleContainer = "nui-modal_title-container",
  ActionContainer = "nui-modal_action-container",
  Badge = "nui-modal_badge",
  Language = "nui-modal_language",
  Title = "nui-modal_title",
  Subtitle = "nui-modal_subtitle",
  Exit = "nui-modal_exit",
  Content = "nui-modal_content",
  Children = "nui-modal_children",
  Footer = "nui-modal_footer",
  Chin = "nui-modal_chin",
}

export enum ModalIds {
  Scrollbars = "Scrollbars",
}

import { CSSProperties, RefObject } from "react";
import { BaseComponentProps, Origin, PortalProps } from "../../definitions";

export interface PopoverProps extends BaseComponentProps, PortalProps {
  /**
   * The element id selector for the component to consume as anchor reference
   */
  anchorTargetElementId?: string;

  /**
   * A JSX ref for the component to consume as anchor reference
   */
  anchorTargetReference?: RefObject<Element>;

  /**
   * Used to determine the target reference's point of origin from which the popover will anchor to
   */
  targetOrigin?: Origin;

  /**
   * Used to determine the popover's point of origin to render from
   */
  popoverOrigin?: Origin;

  /**
   * Used to provide the popover anchor with a margin from the target's origin
   */
  offsetMargin?: string | number;

  /**
   * Used to determine whether or not to render the portal component with a matching width of its target element
   */
  matchWidth?: boolean;

  /**
   * Used to determine whether or not to render the portal component with a matching height of its target element
   */
  matchHeight?: boolean;
}

export type TargetBoundRect = Pick<DOMRect, "top" | "left" | "width" | "height">;

export interface AnchorPositionProperties {
  left: number;
  top: number;
}

export interface PopoverPositionProperties {
  horizontal: Horizontal;
  vertical: Vertical;
  transform: string;
}

type Horizontal = "left" | "right";
type Vertical = "top" | "bottom";

export interface PopoverStyles {
  anchor: CSSProperties;
  inner: CSSProperties;
}

export enum PopoverClassNames {
  Base = "nui-popover",
  Anchor = "nui-popover_anchor",
  Inner = "nui-popover_inner",
}

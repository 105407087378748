// #region Imports
import "./modal.component.scss";
import React, { MouseEvent, memo } from "react";
import { getClassName, getId } from "../../utils/ui";
import { ModalClassNames, ModalIds, ModalProps } from "./modal.definition";
import { ButtonProps, ButtonSize, Opacity, ToolbarTheme } from "../../definitions";
import Button from "../button";
import Portal from "../portal";
import Scrollbars from "../scrollbars";
import Spinner from "../spinner";
import Toolbar from "../toolbar";
// #endregion

/**
 * Modal Component
 */
const Modal = (props: ModalProps): JSX.Element => {
  const {
    children,
    className,
    id,
    loading,
    spinnerProps,
    title,
    subtitle,
    badgeIcon,
    includeExit,
    exitIcon,
    contentHeight,
    headerActions,
    footerActions,
    includeChin,
    fullscreen,
    scrollable,
    onCloseRequest,
    ...portalProps
  } = props;

  const baseClassName = getClassName(ModalClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: loading, trueClassName: ModalClassNames.BaseWithLoadingModifier },
    { condition: scrollable, trueClassName: ModalClassNames.BaseWithScrollableModifier },
    { condition: fullscreen, trueClassName: ModalClassNames.BaseWithFullscreenModifier },
  ]);

  // #region Handler Methods
  /**
   * Handle includeExit's close icon click event
   */
  function handleExitClick(event: MouseEvent): void {
    onCloseRequest && onCloseRequest(event);
  }
  // #endregion

  // #region Render Methods
  function renderButton(props: ButtonProps, index: number): JSX.Element {
    const { key } = props;

    return <Button {...props} key={`modal_action-button--${key || index}`} size={ButtonSize.Large} wide />;
  }
  // #endregion

  return (
    <Portal className={baseClassName} id={id} onCloseRequest={onCloseRequest} maskOpacity={Opacity.High} {...portalProps}>
      <div className={ModalClassNames.Container}>
        {loading && <Spinner {...spinnerProps} />}
        <header className={ModalClassNames.Header}>
          <div className={ModalClassNames.TitleContainer}>
            {!!badgeIcon && (
              <div className={ModalClassNames.Badge}>
                <i className={badgeIcon} />
              </div>
            )}
            <div className={ModalClassNames.Language}>
              <h2 className={ModalClassNames.Title}>{title}</h2>
              {!!subtitle && <h3 className={ModalClassNames.Subtitle}>{subtitle}</h3>}
            </div>
          </div>
          <div className={ModalClassNames.ActionContainer}>
            {(headerActions || []).map((props: ButtonProps, index): JSX.Element => renderButton(props, index))}
            {includeExit && (
              <div className={ModalClassNames.Exit} onClick={handleExitClick}>
                <i className={exitIcon} />
              </div>
            )}
          </div>
        </header>
        <div className={ModalClassNames.Content} style={!fullscreen && contentHeight ? { height: contentHeight } : {}}>
          {scrollable ? (
            <Scrollbars id={getId(id, ModalIds.Scrollbars)}>
              <div className={ModalClassNames.Children}>{children}</div>
            </Scrollbars>
          ) : (
            <div className={ModalClassNames.Children}>{children}</div>
          )}
        </div>
        {footerActions && footerActions.length > 0 && (
          <Toolbar theme={ToolbarTheme.Slate} className={ModalClassNames.Footer} autoRowProps={{ justifyContent: "flex-end" }}>
            {(footerActions || []).map((props: ButtonProps, index): JSX.Element => renderButton(props, index))}
          </Toolbar>
        )}
        {(((!footerActions || !footerActions.length) && scrollable) || includeChin) && <div className={ModalClassNames.Chin} />}
      </div>
    </Portal>
  );
};

Modal.defaultProps = {
  targetElementId: "root",
  targetElementClassName: "overflow-hidden",
  position: "fixed",
  includeExit: true,
  exitIcon: "ni-close-4pt",
  spinnerProps: {
    theme: "modal",
    maskOpacity: Opacity.Full,
  },
  disableEscToClose: false,
};

export default memo(Modal);

import "./grid.component.scss";
import React, { memo } from "react";
import { getClassName } from "../../utils";
import { GridClassNames, GridProps } from "./grid.definition";

/**
 * Note: We're using Toast Grid under the hood https://daneden.github.io/Toast/
 */
function Grid(props: GridProps): JSX.Element {
  const { className, id, dataId, children, gutter } = props;

  const baseClassName = getClassName(GridClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: gutter, falseClassName: GridClassNames.BaseWithNoGutterModifier },
  ]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      {children}
    </div>
  );
}

Grid.defaultProps = {
  gutter: true,
};

export default memo(Grid);

import type moment from "moment";
import { Theme, TextboxTheme, BaseComponentProps } from "../../definitions";

export interface MomentLib {
  default: typeof moment;
}

export enum DatePickerTheme {
  White = Theme.White,
  LightGrey = Theme.LightGrey,
  Rain = Theme.Rain,
  Ink = Theme.Ink,
}

export interface DatePickerProps extends BaseComponentProps {
  theme?: DatePickerTheme;
  textboxTheme?: TextboxTheme;
  value?: moment.Moment;
  format?: string;
  placeholder?: string;
  showToday?: boolean;
  disabled?: boolean;
  /**
   * Used to supply the component with a custom calendar icon className
   */
  calendarIcon: string;
  onChange: Function;
}

export enum DatePickerClassNames {
  Base = "nui-day-picker",
  BaseWithPickerVisibleModifier = "nui-day-picker--open",
  BaseWithDisabledModifier = "nui-day-picker--disabled",
  Icon = "nui-day-picker_icon",
  PopoverContent = "nui-day-picker_popover-content",
  CalendarBase = "nui-day-picker-calendar",
  CalendarWrapper = "nui-day-picker-calendar_wrapper",
  CalendarInteractionDisabled = "nui-day-picker-calendar--disabled",
  CalendarMonths = "nui-day-picker-calendar_months",
  CalendarMonth = "nui-day-picker-calendar_month",
  CalendarNavBar = "nui-day-picker-calendar_actions",
  CalendarNavButtonPrev = "nui-day-picker-calendar_action nui-day-picker-calendar_action--prev ni-arrow-left-4pt",
  CalendarNavButtonNext = "nui-day-picker-calendar_action nui-day-picker-calendar_action--next ni-arrow-right-4pt",
  CalendarNavButtonInteractionDisabled = "nui-day-picker-calendar_action--disabled",
  CalendarCaption = "nui-day-picker-calendar_caption",
  CalendarWeekdays = "nui-day-picker-calendar_weekdays",
  CalendarWeekdaysRow = "nui-day-picker-calendar_weekdays-row",
  CalendarWeekday = "nui-day-picker-calendar_weekday",
  CalendarBody = "nui-day-picker-calendar_body",
  CalendarWeek = "nui-day-picker-calendar_week",
  CalendarWeekNumber = "nui-day-picker-calendar_week-number",
  CalendarDay = "nui-day-picker-calendar_day",
  CalendarFooter = "nui-day-picker-calendar_footer",
  CalendarTodayButton = "nui-day-picker-calendar_today-button",

  // default modifiers
  CalendarToday = "nui-day-picker-calendar_day--today",
  CalendarSelected = "nui-day-picker-calendar_day--selected",
  CalendarDisabled = "nui-day-picker-calendar_day--disabled",
  CalendarOutside = "nui-day-picker-calendar_day--outside",
}

export enum DatePickerIds {
  Input = "Input",
  Popover = "Popover",
  CalendarInput = "CalendarInput",
}

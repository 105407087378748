import React from "react";
import { toast, Slide } from "react-toastify";
import { AppToastOptions } from "./notification.definition";
import { ToastStatus } from "../../definitions";
import { Toast } from "../../components";

export default class NotificationService {
  // https://github.com/fkhadra/react-toastify#api
  private readonly toastConfig: AppToastOptions = {
    autoClose: 5000,
    className: "",
    closeOnClick: false,
    draggablePercent: 20,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    toastClassName: "",
    transition: Slide,
  };

  success = (message: string, options: AppToastOptions = null): void => {
    const content = <Toast message={message} type={ToastStatus.Success} />;
    toast.success(content, this.extendOptions(options));
  };

  info = (message: string, options: AppToastOptions = null): void => {
    const content = <Toast message={message} type={ToastStatus.Info} />;
    toast.info(content, this.extendOptions(options));
  };

  warn = (message: string, options: AppToastOptions = null): void => {
    const content = <Toast message={message} type={ToastStatus.Warning} />;
    toast.warn(content, this.extendOptions(options));
  };

  error = (message: string, options: AppToastOptions = null): void => {
    const content = <Toast message={message} type={ToastStatus.Error} />;
    toast.error(content, this.extendOptions(options));
  };

  dismiss = (): void => {
    toast.dismiss();
  };

  private extendOptions(options: AppToastOptions = {}): AppToastOptions {
    return Object.assign(this.toastConfig, options);
  }
}

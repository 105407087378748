import { BaseComponentProps } from "../../definitions";
import { ReactNode } from "react";

export interface SwapableProps extends Omit<BaseComponentProps, "children"> {
  layers: ReactNode[];
  selected: number;
  onSwapStart?: Function;
  onSwapEnd?: Function;
}

export enum SwapableClassNames {
  Base = "nui-swapable",
  BaseWithTransitionModifier = "nui-swapable--transition",
  Layer = "nui-swapable_layer",
}

import "./search.component.scss";
import React, { memo, useRef, useEffect, useState, FocusEvent, ChangeEvent, KeyboardEvent } from "react";
import { DebounceDelay, KeyboardKey, SearchClassNames, SearchIds, SearchProps, SearchTheme } from "../../definitions";
import { getClassName, getId, useDidMount } from "../../utils";

function Search(props: SearchProps): JSX.Element {
  const {
    className,
    id,
    dataId,
    theme,
    value,
    fieldIcon,
    clearIcon,
    disabled,
    placeholder,
    maxLength,
    minQueryLength,
    buffer,
    expandOnFocus,
    onInputFocus,
    onInputBlur,
    onInputChange,
    onQueryRequest,
    onClear,
  } = props;

  const didMount = useDidMount();
  const inputReference = useRef(null);
  const [focused, setFocused] = useState(false);

  const baseClassName = getClassName(SearchClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${SearchClassNames.Base}--${theme}` },
    { condition: !!value, falseClassName: SearchClassNames.BaseWithEmptyModifier },
    { condition: expandOnFocus && focused, trueClassName: SearchClassNames.BaseWithExpandedModifier },
    { condition: disabled, trueClassName: SearchClassNames.BaseWithDisabledModifier },
  ]);

  /**
   * Handle onQueryRequest or onClear firing based on value change
   */
  useEffect(() => {
    function handleValueChange(newValue: string): void {
      if (newValue.trim().length >= minQueryLength) {
        onQueryRequest && onQueryRequest(newValue);
      }

      if (newValue.trim().length === 0) {
        onClear && onClear();
      }
    }

    const queryTimeout = setTimeout(() => {
      didMount && handleValueChange(value);
    }, buffer);

    return (): void => {
      queryTimeout && clearTimeout(queryTimeout);
    };
  }, [buffer, didMount, minQueryLength, onClear, onQueryRequest, value]);

  /**
   * Reapply focus to the internal input element
   */
  function refocusInput(): void {
    const Input = inputReference && inputReference.current;
    Input && Input.focus();
  }

  /**
   * Handle onInputFocus event
   */
  function handleInputFocus(event: FocusEvent): void {
    setFocused(true);
    onInputFocus && onInputFocus(event);
  }

  /**
   * Handle onInputBlur event
   */
  function handleInputBlur(event: FocusEvent): void {
    setFocused(false);
    onInputBlur && onInputBlur(event);
  }

  /**
   * handle onInputChange event
   * @param event
   */
  function handleInputChange(event?: ChangeEvent): void {
    const target = event?.target;
    const newValue = (target && (target as HTMLInputElement).value) || "";

    onInputChange && onInputChange(newValue, event);
  }

  /**
   * Handle Search component's X onClick event
   */
  function handleClear(): void {
    refocusInput();
    handleInputChange();
  }

  /**
   * Handle onKeyDown event
   * @param event
   */
  function handleKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case KeyboardKey.Escape:
      case KeyboardKey.Esc:
        return value && handleClear();
      default:
        return;
    }
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <div className={SearchClassNames.Inner}>
        <div className={SearchClassNames.Icon}>
          <i className={fieldIcon} />
        </div>
        <input
          ref={inputReference}
          className={SearchClassNames.Input}
          id={getId(id, SearchIds.Input)}
          name="value"
          placeholder={placeholder || "Search"}
          aria-label={placeholder || "Search"}
          maxLength={maxLength}
          value={value}
          readOnly={disabled}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
        />
        {value && <i className={`${SearchClassNames.Clear} ${clearIcon}`} onClick={handleClear} />}
      </div>
    </div>
  );
}

Search.defaultProps = {
  theme: SearchTheme.Steel,
  value: "",
  placeholder: "Search",
  fieldIcon: "ni-search-4pt",
  clearIcon: "ni-close-4pt",
  minQueryLength: 2,
  buffer: DebounceDelay,
  expandOnFocus: false,
};

export default memo(Search);

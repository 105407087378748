import { BaseComponentProps, ButtonProps, LayoutProps, Theme } from "../../definitions";

export interface PlaceholderContentProps extends Omit<BaseComponentProps, "children"> {
  /**
   * Used to paint the component using a specific theme
   */
  theme?: PlaceholderContentTheme;

  /**
   * Used to provide internal Layout component with props
   */
  layoutProps?: LayoutProps;

  /**
   * The q4 icon class used to paint the icon above the title
   */
  icon?: string;

  /**
   * A path to an image used for painting above the title
   */
  image?: string;

  /**
   * Used to determine the title of the component
   */
  title: string;

  /**
   * Used to determine the message of the component
   */
  subtitle?: string;

  /**
   * Used to determine the action buttons of the component
   * Note: Use standard Button props
   */
  actions?: ButtonProps[];

  /**
   * Used to determine the caption of the component
   * (sits below the buttons)
   */
  caption?: string;
}

export enum PlaceholderContentTheme {
  Light = Theme.Light,
  Dark = Theme.Dark,
  Transparent = Theme.Transparent,
}

export enum PlaceholderContentClassNames {
  Base = "nui-placeholder-content",
  Inner = "nui-placeholder-content_inner",
  Icon = "nui-placeholder-content_icon",
  Image = "nui-placeholder-content_image",
  Title = "nui-placeholder-content_title",
  Subtitle = "nui-placeholder-content_subtitle",
  Actions = "nui-placeholder-content_actions",
  Caption = "nui-placeholder-content_caption",
}

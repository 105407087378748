import { ReactFocusOnProps } from "react-focus-on/dist/es2015/types";
import { PositionProperty } from "csstype";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import { BaseComponentProps, TransitionSpeed, Opacity } from "../../definitions";

export interface PortalProps extends BaseComponentProps {
  /**
   * Used to determine the id of the element that the Portal will append itself to
   * Note: there is no need to provide the # prefix
   */
  targetElementId?: string;

  /**
   * Used to determine the target element's added className when the Portal is visible
   */
  targetElementClassName?: string;

  /**
   * Used to determine the CSS position attribute's value for the Portal content
   */
  position?: Extract<PositionProperty, "fixed" | "absolute" | "static">;

  /**
   * Used to determine whether or not the component should appear visible
   */
  visible: boolean;

  /**
   * Used to determine whether or not to render a mask inside the Portal
   */
  masked?: boolean;

  /**
   * Used to determine the opacity of the painted mask
   */
  maskOpacity?: Opacity;

  /**
   * Used to determine the speed of the Portal during transition phases
   */
  transitionSpeed?: TransitionSpeed;

  /**
   * Used to determine whether or not to disable use of the Esc key to hide the Portal
   */
  disableEscToClose?: boolean;

  /**
   * Used to determine whether or not to disable use of clicking the mask to hide the Portal
   */
  disableMaskClickToClose?: boolean;

  /**
   * Used to supply an internal FocusLock component with props
   */
  focusOnProps?: ReactFocusOnProps;

  /**
   * Used to supply an internal CSS-Transition (should be Ghostable) component with props
   */
  cssTransitionProps?: Partial<CSSTransitionProps>;

  /**
   * A callback for when the Portal becomes visible
   */
  onOpen?: Function;

  /**
   * A callback for when the Portal requests to be closed from the inside
   */
  onCloseRequest?: Function;
}

export enum PortalClassNames {
  Base = "nui-portal",
  Mask = "nui-portal_mask",
  Content = "nui-portal_content",
}

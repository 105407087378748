import { BaseComponentProps, Theme } from "../../definitions";

export enum ToastTheme {
  White = Theme.White,
  LightGrey = Theme.LightGrey,
}

export enum ToastStatus {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export interface ToastProps extends Omit<BaseComponentProps, "children"> {
  theme?: ToastTheme;
  type: ToastStatus;
  indicatorIcon?: string;
  closeIcon?: string;
  message: JSX.Element | string;
  closeToast?: Function;
}

export enum ToastClassNames {
  Base = "nui-toast",
  Indicator = "nui-toast_indicator",
  Message = "nui-toast_message",
  Close = "nui-toast_close",
}

import type { AgGridReact, AgGridColumn, AgGridReactProps } from "ag-grid-react";
import { BaseComponentProps } from "../../definitions";

export interface AgGridReactLib {
  AgGridReact: typeof AgGridReact;
  AgGridColumn: typeof AgGridColumn;
}

export enum TableRowDensity {
  Default = 48,
  Comfy = 40,
  Compact = 26,
}

export interface TableProps extends AgGridReactProps, Pick<BaseComponentProps, "className" | "dataId" | "id"> {
  /**
   * Used used to show a loading while the grid waits for data
   */
  loading: boolean;

  /**
   * Used to put the grid in pinmode
   */
  pinnedMode?: boolean;
  /**
   * Used to have the grid attempt auto-sizing after firing onGridReady callback
   * Note: convenience function
   */
  sizeToFit: boolean;

  /**
   * Used to determine row height
   * Note: changing rowHeight in Ag-grid requires both JS and CSS, which is why this prop was introduced
   */
  rowDensity: keyof typeof TableRowDensity;
}

export enum TableClassNames {
  Base = "nui-table",
  BaseWithAutoHeightModifier = "nui-table--auto-height",
  BaseWithPaginationModifier = "nui-table--paginated",
  BaseWithNoColumnsModifier = "nui-table--no-columns",
  BaseWithNoDataModifier = "nui-table--no-data",
  BaseWithPivotModeModifier = "nui-table--pivot",
  BaseWithPinnedModeModifier = "nui-table--pinned",
  Row = "ag-row",
  RowWithOnClickedModifier = "ag-row--clickable",
  Cell = "ag-cell",
}

import "./toastContainer.component.scss";
import React, { memo } from "react";
import { ToastContainer as Component, ToastContainerProps } from "react-toastify";

/**
 * ToastContainer Wrapper Component
 */
const ToastContainer = (props: ToastContainerProps): JSX.Element => {
  return <Component closeButton={false} {...props} />;
};

export default memo(ToastContainer);

import "./datePicker.component.scss";
import React, { KeyboardEvent, useMemo, useRef, useState, memo, useEffect } from "react";
import loadable from "@loadable/component";
const LoadableDayPicker = loadable(() => import("react-day-picker"));
import { DateFormat, DatePickerTheme, DatePickerClassNames, DatePickerIds, KeyboardKey, Origin, TextboxTheme } from "../../definitions";
import type { DatePickerProps, MomentLib } from "../../definitions";
import { getClassName, getId, isEmpty } from "../../utils";
import Popover from "../popover";
import Spinner from "../spinner";
import Textbox from "../textbox";

const DatePicker = (props: DatePickerProps): JSX.Element => {
  // This component uses React-Day-Picker
  // http://react-day-picker.js.org/docs/
  const { id, dataId, className, disabled, placeholder, showToday, theme, value, format, calendarIcon, onChange, textboxTheme } = props;

  const [pickerVisible, setPickerVisible] = useState(false);
  const shouldOpenPicker = useRef(true);
  const [textDate, setTextDate] = useState(getTextDateFromValue);
  const valueAsDate = value?.toDate() ?? null;
  const inputRef = useRef<HTMLInputElement>();
  const calendarRef = useRef<HTMLDivElement>();
  const datePickerThemeForTextbox: TextboxTheme = (theme as unknown) as TextboxTheme;
  const momentRef = useRef<MomentLib>();

  useMemo(handleLoadingMoment, []);

  const baseClassName = getClassName(DatePickerClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${DatePickerClassNames.Base}--${theme}` },
    { condition: pickerVisible, trueClassName: DatePickerClassNames.BaseWithPickerVisibleModifier },
    { condition: disabled, trueClassName: DatePickerClassNames.BaseWithDisabledModifier },
  ]);

  useEffect(handleValueChange, [value]);

  function isMomentLoaded(): boolean {
    if (isEmpty(momentRef.current?.default)) {
      console.error("moment failed to load");
      return false;
    }
    return true;
  }

  function getTextDateFromValue(): string {
    return value?.format(format) ?? "";
  }

  function handleLoadingMoment(): void {
    import("moment").then((moment) => {
      momentRef.current = moment;
    });
  }

  function handleValueChange(): void {
    setTextDate(getTextDateFromValue());
  }

  function handlePopoverOpen(): void {
    setPickerVisible(true);
  }

  function handlePopoverClose(): void {
    setPickerVisible(false);
  }

  function handlePopoverCloseRequest(): void {
    handleTextToChange();
    handlePopoverClose();
  }

  function handlePopoverEnter(): void {
    shouldOpenPicker.current = false;
  }

  function handlePopoverExited(): void {
    shouldOpenPicker.current = true;
  }

  function handleDayChange(day: Date): void {
    if (!isMomentLoaded()) return;
    const momentDay = momentRef.current.default(day);

    onChange(momentDay);
    handlePopoverClose();
  }

  function handleTextToChange(): void {
    if (!isMomentLoaded()) return;
    const momentDate = momentRef.current.default(textDate, format, true);

    if (momentDate.isValid() && !momentDate.isSame(value)) {
      onChange(momentDate);
    } else {
      // reject the change
      setTextDate(getTextDateFromValue());
    }
  }

  function handleInputKeyDown(evt: KeyboardEvent): void {
    if (evt.key === KeyboardKey.Enter) {
      handlePopoverClose();
    }
  }

  function handleInputChange(text: string): void {
    setTextDate(text);
  }

  function handleInputClick(): void {
    handlePopoverOpen();
  }

  function handleInputFocus(): void {
    if (shouldOpenPicker.current) {
      return handlePopoverOpen();
    }
  }

  function handleInputBlur(): void {
    handleTextToChange();
  }

  function renderDayPicker(): JSX.Element {
    return (
      <LoadableDayPicker
        fallback={<Spinner />}
        classNames={{
          container: getClassName(DatePickerClassNames.CalendarBase, [
            { condition: !!className, trueClassName: className },
            { condition: !!theme, trueClassName: `${DatePickerClassNames.Base}--${theme}` },
          ]),
          wrapper: DatePickerClassNames.CalendarWrapper,
          interactionDisabled: DatePickerClassNames.CalendarInteractionDisabled,
          months: DatePickerClassNames.CalendarMonths,
          month: DatePickerClassNames.CalendarMonth,
          navBar: DatePickerClassNames.CalendarNavBar,
          navButtonPrev: DatePickerClassNames.CalendarNavButtonPrev,
          navButtonNext: DatePickerClassNames.CalendarNavButtonNext,
          navButtonInteractionDisabled: DatePickerClassNames.CalendarNavButtonInteractionDisabled,
          caption: DatePickerClassNames.CalendarCaption,
          weekdays: DatePickerClassNames.CalendarWeekdays,
          weekdaysRow: DatePickerClassNames.CalendarWeekdaysRow,
          weekday: DatePickerClassNames.CalendarWeekday,
          body: DatePickerClassNames.CalendarBody,
          week: DatePickerClassNames.CalendarWeek,
          weekNumber: DatePickerClassNames.CalendarWeekNumber,
          day: DatePickerClassNames.CalendarDay,
          footer: DatePickerClassNames.CalendarFooter,
          todayButton: DatePickerClassNames.CalendarTodayButton,

          // default modifiers
          today: showToday ? DatePickerClassNames.CalendarToday : "",
          selected: DatePickerClassNames.CalendarSelected,
          disabled: DatePickerClassNames.CalendarDisabled,
          outside: DatePickerClassNames.CalendarOutside,
        }}
        enableOutsideDaysClick={true}
        selectedDays={valueAsDate}
        month={valueAsDate}
        showOutsideDays={true}
        onDayClick={handleDayChange}
      />
    );
  }

  return (
    <div id={id} data-id={dataId} className={baseClassName} ref={calendarRef}>
      <Textbox
        inputId={getId(id, DatePickerIds.Input)}
        inputRef={inputRef}
        value={textDate}
        placeholder={placeholder}
        disabled={disabled}
        theme={textboxTheme || datePickerThemeForTextbox}
        onFocus={handleInputFocus}
        onClick={handleInputClick}
        onKeyDown={handleInputKeyDown}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
      <div className={`${DatePickerClassNames.Icon} ${calendarIcon}`} />
      <Popover
        className={baseClassName}
        id={getId(id, DatePickerIds.Popover)}
        visible={pickerVisible}
        anchorTargetReference={inputRef}
        targetOrigin={Origin.TopLeft}
        popoverOrigin={Origin.TopLeft}
        cssTransitionProps={{
          onEnter: handlePopoverEnter,
          onExited: handlePopoverExited,
        }}
        onCloseRequest={handlePopoverCloseRequest}
      >
        <div className={DatePickerClassNames.PopoverContent}>
          <Textbox
            inputId={getId(id, DatePickerIds.CalendarInput)}
            value={textDate}
            placeholder={placeholder}
            theme={datePickerThemeForTextbox}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
          {renderDayPicker()}
        </div>
      </Popover>
    </div>
  );
};

DatePicker.defaultProps = {
  disabled: false,
  format: DateFormat,
  theme: DatePickerTheme.LightGrey,
  placeholder: "Select date",
  showToday: false,
  calendarIcon: "ni-calendar-4pt",
};

export default memo(DatePicker);

import { useState, useEffect } from "react";
import { GridUnit, ConditionalClassName } from "../../definitions/ui.definition";
import { CommonWrapper } from "enzyme";

/**
 * Return a value that equals to the standard grid unit multiplied by the supplied multiplier
 */
export const gux = (multiplier = 1): number => {
  if (isNaN(multiplier)) {
    return GridUnit;
  }

  return GridUnit * multiplier;
};

/**
 * Return a className string based on array of conditions and results
 */
export const getClassName = (baseClassName: string, array: ConditionalClassName[]): string => {
  let className = baseClassName || "";

  (array || []).forEach((classItem) => {
    if (classItem.condition && classItem.trueClassName) {
      className += ` ${classItem.trueClassName}`;
    }

    if (!classItem.condition && classItem.falseClassName) {
      className += ` ${classItem.falseClassName}`;
    }
  });

  return className;
};

/**
 * Return an id string based on a baseId and suffix
 */
export const getId = (baseId: string, suffixId: string): string => {
  return !!baseId && !!suffixId ? `${baseId}${suffixId}` : null;
};

/**
 * Helper function for providing functional components with componentDidMount checking
 */
export const useDidMount = (): boolean => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => setDidMount(true), []);

  return didMount;
};

/**
 * Helper function to determine if a string is truthy or not
 */
export function isNullOrWhiteSpace(n: string): boolean {
  return !n || n.length === 0 || /^\s*$/.test(n);
}

/**
 * Helper function to convert snake case string to pascal case string
 */
export function snakeToPascalCase(s: string): string {
  return s.replace(/(\w)(\w*)/g, (m, g0, g1) => g0.toUpperCase() + g1.toLowerCase()).replace("-", "");
}

/**
 * Helper function to determine the truthiness of a param
 */
export function isEmpty<T>(value: T): boolean {
  const valid = !!value;
  if (valid && !(Array.isArray(value) || typeof value === "object")) return false;

  if (Array.isArray(value) && value.length) return false;

  if (typeof value === "object" && valid && Object.keys(value).length) return false;

  return true;
}

/**
 * Helper function to find if something is undefined | null
 */
export function isNil<T>(value: T): boolean {
  return value === undefined || value === null;
}

export function triggerRerender(wrapper: CommonWrapper): void {
  // Note: cannot find a way for wrapper to rerender without a setProps call. Want to remove this.
  wrapper.setProps({ x: Math.random() });
}

import { ErrorHandlerField, ErrorHandlerMessage } from "./errorHandler.definition";
import { isEmpty } from "../../utils";

export default class ErrorHandlerService<TKey, TEntity> {
  private _errorMessages: ErrorHandlerMessage<TKey, TEntity>[];
  private readonly _errorFields: ErrorHandlerField<TEntity>[];

  constructor(errorFields: ErrorHandlerField<TEntity>[]) {
    this._errorMessages = [];
    this._errorFields = errorFields;
  }

  getAll = (): ErrorHandlerMessage<TKey, TEntity>[] => this._errorMessages;

  get = (id: TKey, prop: keyof TEntity): ErrorHandlerMessage<TKey, TEntity> => {
    const error = this._errorMessages.find((x): boolean => x.id === id && x.prop === prop);
    return { ...error, visible: !isEmpty(error) };
  };

  hasErrors = (): boolean => !isEmpty(this._errorMessages);

  checkForErrors(id: TKey, item: TEntity): void {
    this._errorFields.forEach((requiredField): void => {
      const { errorMessage, prop, condition } = requiredField;
      const value = item[prop];

      if (isEmpty(value) || (!isEmpty(condition) && !condition(value))) {
        this._add(id, prop, errorMessage);
      } else if (!isEmpty(value) || (!isEmpty(condition) && condition(value))) {
        this.delete(id, prop);
      }
    });
  }

  checkError(id: TKey, item: Partial<TEntity>, propToCheck: keyof TEntity): void {
    const requiredField = this._errorFields.find((r): boolean => r.prop === propToCheck);
    const { errorMessage, prop, condition } = requiredField;
    const value = item[prop];

    if ((isEmpty(condition) && isEmpty(value)) || (!isEmpty(condition) && !condition(value))) {
      this._add(id, prop, errorMessage);
    } else if ((isEmpty(condition) && !isEmpty(value)) || (!isEmpty(condition) && condition(value))) {
      this.delete(id, prop);
    }
  }

  private _add(id: TKey, prop: keyof TEntity, message: string): void {
    const containsErrorMessage = this._errorMessages.find((x): boolean => x.prop === prop && x.id === id);

    if (!containsErrorMessage) {
      this._errorMessages.push(new ErrorHandlerMessage(id, prop, message));
    }
  }

  delete(id: TKey, prop: keyof TEntity): void {
    const errIndex = this._errorMessages.findIndex((x): boolean => x.id === id && x.prop === prop);

    if (errIndex > -1) {
      this._errorMessages.splice(errIndex, 1);
    }
  }

  clear(): void {
    this._errorMessages = [];
  }
}

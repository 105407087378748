import "./ghostable.component.scss";
import React from "react";
import { CSSTransition } from "react-transition-group";
import { AnimateTime, ConditionalClassName, GhostableClassNames, GhostableProps } from "../../definitions";
import { getClassName } from "../../utils";

const Ghostable = (props: GhostableProps): JSX.Element => {
  const {
    children,
    className,
    id,
    dataId,
    appear,
    delay,
    ghosted,
    mountOnEnter,
    unmountOnExit,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
  } = props;

  function handleEnter(node: HTMLElement, isAppearing: boolean): void {
    onEnter && onEnter(node, isAppearing);
  }

  function handleEntering(node: HTMLElement, isAppearing: boolean): void {
    onEntering && onEntering(node, isAppearing);
  }

  function handleEntered(node: HTMLElement, isAppearing: boolean): void {
    onEntered && onEntered(node, isAppearing);
  }

  function handleExit(node: HTMLElement): void {
    onExit && onExit(node);
  }

  function handleExiting(node: HTMLElement): void {
    onExiting && onExiting(node);
  }

  function handleExited(node: HTMLElement): void {
    onExited && onExited(node);
  }

  function getBaseClassName(stateClassName: string, conditions?: ConditionalClassName[]): string {
    return getClassName(stateClassName, [{ condition: !!className, trueClassName: className }, ...(conditions || [])]);
  }

  return (
    <CSSTransition
      id={id}
      data-id={dataId}
      appear={appear}
      in={!ghosted}
      classNames={{
        appear: getBaseClassName(GhostableClassNames.GhostWithDoneModifier),
        appearActive: getBaseClassName(GhostableClassNames.SolidWithTransitionModifier, [
          { condition: delay > 0, trueClassName: `${GhostableClassNames.SolidWithTransitionModifier}-${delay}x-delay` },
        ]),
        appearDone: getBaseClassName(GhostableClassNames.SolidWithDoneModifier),
        enter: getBaseClassName(GhostableClassNames.GhostWithDoneModifier),
        enterActive: getBaseClassName(GhostableClassNames.SolidWithTransitionModifier, [
          { condition: delay > 0, trueClassName: `${GhostableClassNames.SolidWithTransitionModifier}-${delay}x-delay` },
        ]),
        enterDone: getBaseClassName(GhostableClassNames.SolidWithDoneModifier),
        exit: getBaseClassName(GhostableClassNames.SolidWithDoneModifier),
        exitActive: getBaseClassName(GhostableClassNames.GhostWithTransitionModifier),
        exitDone: getBaseClassName(GhostableClassNames.GhostWithDoneModifier),
      }}
      timeout={AnimateTime * (delay + 1)}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      onExited={handleExited}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      {children}
    </CSSTransition>
  );
};

Ghostable.defaultProps = {
  appear: true,
  delay: 0,
  mountOnEnter: true,
  unmountOnExit: true,
};
export default Ghostable;

import "./checkbox.component.scss";
import React, { ChangeEvent, memo, MouseEvent } from "react";
import { CheckboxClassNames, CheckboxIds, CheckboxProps, CheckboxSize, CheckboxTheme } from "../../definitions";
import { getClassName, getId } from "../../utils";

/**
 * Checkbox Component
 */
const Checkbox = (props: CheckboxProps): JSX.Element => {
  const {
    className,
    id,
    dataId,
    theme,
    size,
    name,
    label,
    labelAlign,
    checkmarkIcon,
    customCheckboxColor,
    boxOnly,
    inline,
    checked,
    disabled,
    stopPropagation,
    onChange,
  } = props;

  const baseClassName = getClassName(CheckboxClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${CheckboxClassNames.Base}--${theme}` },
    { condition: !!customCheckboxColor, trueClassName: CheckboxClassNames.BaseWithCustomColorModifier },
    { condition: !!size, trueClassName: `${CheckboxClassNames.Base}--${size}` },
    { condition: checked, trueClassName: CheckboxClassNames.BaseWithCheckedModifier },
    { condition: boxOnly, trueClassName: CheckboxClassNames.BaseWithBoxOnlyModifier },
    { condition: inline, trueClassName: CheckboxClassNames.BaseWithInlineModifier },
    { condition: disabled, trueClassName: CheckboxClassNames.BaseWithDisabledModifier },
  ]);

  const handleStoppedClick = (event: MouseEvent): void => event.stopPropagation();
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(event.target?.checked, event);
  };

  function renderLabel(): JSX.Element {
    return (
      <label
        className={`${CheckboxClassNames.Label} ${CheckboxClassNames.Label}--${labelAlign}`}
        htmlFor={getId(id, CheckboxIds.Input)}
        onClick={stopPropagation ? handleStoppedClick : null}
      >
        {labelAlign === "right" && renderBox()}
        {!boxOnly && label}
        {labelAlign === "left" && renderBox()}
      </label>
    );
  }

  function renderBox(): JSX.Element {
    let boxStyle = {};

    if (checked && customCheckboxColor) {
      boxStyle = {
        backgroundColor: customCheckboxColor,
      };
    }

    return (
      <div className={CheckboxClassNames.Box} style={boxStyle}>
        <i className={`${CheckboxClassNames.Icon} ${checkmarkIcon}`} />
      </div>
    );
  }

  function renderInput(): JSX.Element {
    return (
      <input
        className={CheckboxClassNames.Input}
        type="checkbox"
        id={getId(id, CheckboxIds.Input)}
        name={name}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
    );
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId} onClick={stopPropagation ? handleStoppedClick : null}>
      {labelAlign === "left" && (boxOnly || label) && renderLabel()}
      {renderInput()}
      {labelAlign === "right" && (boxOnly || label) && renderLabel()}
    </div>
  );
};

Checkbox.defaultProps = {
  theme: CheckboxTheme.Steel,
  boxOnly: false,
  checked: false,
  size: CheckboxSize.Default,
  labelAlign: "right",
  checkmarkIcon: "ni-checkmark-4pt",
};

export default memo(Checkbox);

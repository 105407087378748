// #region Imports
import "./dropdown.component.scss";
import React, { FocusEvent, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { getClassName, isEmpty, isNil, isNullOrWhiteSpace } from "../../utils";
import { KeyboardKey, TextboxSize } from "../../definitions";
import { DropdownProps, DropdownState, DropdownStyles, DropdownTheme, getComponentSize } from "./dropdown.definition";
import FocusInOut from "../focusInOut";
import Scrollbars from "../scrollbars";
import Textbox from "../textbox";
import FocusService from "../../services/focus/focus.service";
// #endregion

const Dropdown = <T extends {}>(props: DropdownProps<T>): JSX.Element => {
  const filterRef = useRef<HTMLInputElement>();
  const componentRef = useRef<HTMLDivElement>();
  const optionsRef = useRef<HTMLDivElement>();
  const appBodyContainer = useRef<Element>(document.getElementsByTagName("body")[0]);
  const optionContainer = useRef<Element>(document.createElement("div"));
  const focusService = useRef(new FocusService());
  const [state, setState] = useState({
    expanded: false,
    filtered: [],
    filterValue: "",
    toggleCount: 0,
  });
  const [forceUpdateValue, setForceUpdateValue] = useState(false); // this needs to be removed

  // #region Helper Methods
  function forceUpdate(): void {
    setForceUpdateValue(!forceUpdateValue);
  }

  function isSelected(option: T): boolean {
    const { optionKey, value } = props;
    return isNil(optionKey) ? value === option : !isEmpty(value) && value[optionKey] === option[optionKey];
  }

  function isFiltered(): boolean {
    const { filterValue } = state;
    return !isNullOrWhiteSpace(filterValue);
  }

  function getCurrentOptions(): T[] {
    const { options } = props;
    const { filtered } = state;
    return isFiltered() ? filtered : options;
  }

  function getHeight(): number {
    const { dropdownHeight, hasFilter, small } = props;
    const currentOptions = getCurrentOptions();
    const hasItems = !isEmpty(currentOptions);

    const itemHeight = getComponentSize(small);
    const filterHeight = hasFilter ? itemHeight : 0;

    if (!hasItems) return itemHeight + filterHeight;
    const totalItemHeight = currentOptions.length * itemHeight + filterHeight;
    if (isNil(dropdownHeight)) {
      const maxHeight = itemHeight * 4.5 + filterHeight;

      return Math.min(totalItemHeight, maxHeight);
    }
    return Math.min(dropdownHeight, totalItemHeight);
  }

  function getStyles(): DropdownStyles {
    const { small, width } = props;
    const { toggleCount } = state;
    const dropdownHeight = getHeight();

    return new DropdownStyles(width, dropdownHeight, toggleCount, componentRef, small);
  }

  function getLabel(option: T, index: number): {} {
    const { options, optionKey, customOptionRender } = props;

    if (!isEmpty(optionKey)) {
      return option[optionKey];
    }

    if (customOptionRender) {
      return customOptionRender(option, index, options);
    }

    return option;
  }

  function removeOptions(): void {
    if (!state.expanded) return;
    if (appBodyContainer.current.contains(optionContainer.current)) appBodyContainer.current.removeChild(optionContainer.current);
  }
  // #endregion

  // #region Handle Methods
  function handleCollapse(): void {
    const { expanded } = state;
    if (!expanded) return;

    setState(
      (prevState): DropdownState<T> => ({
        expanded: false,
        toggleCount: prevState.toggleCount + 1,
        filterValue: "",
        filtered: [],
      })
    );
    removeOptions();
  }

  function handleExpand(): void {
    setState(
      (prevState): DropdownState<T> => ({
        ...state,
        expanded: true,
        toggleCount: prevState.toggleCount + 1,
      })
    );
    appBodyContainer.current.appendChild(optionContainer.current);
    filterRef.current && filterRef.current.focus();
  }

  function handleToggle(): void {
    const { disabled } = props;

    if (!disabled) {
      const { onToggle } = props;
      !isEmpty(onToggle) && onToggle();

      const { expanded } = state;
      expanded ? handleCollapse() : handleExpand();
    }
  }

  function handleSelect(option: T): void {
    const { onSelect } = props;
    handleCollapse();
    onSelect && onSelect(option);
  }

  function handleFilterBlur(event: FocusEvent): void {
    focusService.current.shouldBlur(event, componentRef, optionsRef).then((shouldBlur): void => {
      if (shouldBlur) handleCollapse();
    });
  }

  function handleFilterChange(filterValue: string): void {
    const { optionKey } = props;
    const sanitizedFilter = filterValue.toLowerCase().trim();
    setState({
      ...state,
      filterValue,
      filtered: props.options.filter((x): boolean => {
        const option = (isEmpty(optionKey) ? x : x[optionKey]).toString().toLowerCase();
        return option.includes(sanitizedFilter);
      }),
    });
  }

  function handleKeyDown(evt: KeyboardEvent): void {
    switch (evt.key) {
      case KeyboardKey.Esc:
      case KeyboardKey.Escape:
        handleCollapse();
    }
  }

  function handleResize(): void {
    if (!state.expanded) return;

    forceUpdate();
  }
  // #endregion

  // #region Lifecycle Methods
  function handleComponentMount(): () => void {
    optionContainer.current.className = "nui-dropdown-portal";
    return (): void => removeOptions();
  }
  useEffect(handleComponentMount, []);

  function handleEventListener(): () => void {
    if (!state.expanded) return;
    document.addEventListener("keydown", handleKeyDown);
    window.addEventListener("resize", handleResize);

    return (): void => {
      if (!state.expanded) return;
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", handleResize);
    };
  }
  useEffect(handleEventListener, [state.expanded]);
  // #endregion

  // #region Render Methods
  function renderOptions(hasFilter: boolean): JSX.Element {
    const { filterValue } = state;
    const currentOptions = getCurrentOptions();

    return (
      <Scrollbars>
        <ul className="nui-dropdown_list">
          {hasFilter && (
            <li className="nui-dropdown_filter">
              <Textbox size={TextboxSize.Small} inputRef={filterRef} value={filterValue} onChange={handleFilterChange} onBlur={handleFilterBlur} />
            </li>
          )}
          {isEmpty(currentOptions) ? (
            <span className="nui-dropdown_no-data-text">No Data Available</span>
          ) : (
            currentOptions.map(
              (option, index): JSX.Element => {
                return (
                  <li
                    key={`nui-dropdown-option_${option}-${index}`}
                    className={getClassName("nui-dropdown_option", [
                      {
                        condition: isSelected(option),
                        trueClassName: "nui-dropdown_option--selected",
                      },
                    ])}
                    onClick={(): void => handleSelect(option)}
                    tabIndex={0}
                  >
                    {getLabel(option, index)}
                  </li>
                );
              }
            )
          )}
        </ul>
      </Scrollbars>
    );
  }
  // #endregion

  const { id, className, theme, value, optionKey, placeholder, disabled, small, hasFilter, scrollContainerClass } = props;
  const { expanded } = state;

  const styles = getStyles();
  const label = value ? (optionKey ? value[optionKey] || placeholder || "Select" : value || placeholder || "Select") : placeholder || "Select";

  const baseClassName = getClassName("nui-dropdown", [
    { condition: !isNullOrWhiteSpace(className), trueClassName: className },
    {
      condition: !isEmpty(theme),
      trueClassName: `nui-dropdown--${theme}`,
      falseClassName: `nui-dropdown--${Dropdown.defaultProps.theme}`,
    },
    { condition: expanded, trueClassName: "nui-dropdown--expanded" },
    { condition: disabled, trueClassName: "nui-dropdown--disabled" },
    { condition: small, trueClassName: "nui-dropdown--small" },
    { condition: label === placeholder, trueClassName: "nui-dropdown--placeholder" },
  ]);

  return (
    <div id={id} className={baseClassName} style={styles.base} ref={componentRef}>
      <FocusInOut scrollContainerClass={scrollContainerClass} onBlur={handleCollapse} componentRef={componentRef} parentComponentRef={optionsRef}>
        <div className="nui-dropdown_inner" style={styles.inner} onClick={handleToggle}>
          <span className="nui-dropdown_value">{label}</span>
          <div className="nui-dropdown_toggle" style={styles.toggle}>
            <i className="ni-arrow-down-4pt" />
          </div>
        </div>
      </FocusInOut>
      {ReactDOM.createPortal(
        <div className={`${baseClassName} nui-dropdown_options`} style={styles.position} ref={optionsRef}>
          <div className="nui-dropdown_filler" onClick={handleCollapse} />
          <div className="nui-dropdown_options-list">{renderOptions(hasFilter)}</div>
        </div>,
        optionContainer.current
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  theme: DropdownTheme.LightGrey,
  width: 140,
  forceDropdownHeight: false,
  placeholder: "Select",
  hasFilter: false,
  scrollContainerClass: ".layout",
};

export default Dropdown;

export class ErrorModel {
  visible = false;
  icon: string;
  message: string;

  constructor(message: string, icon = "ni-warning-4pt") {
    this.message = message;
    this.icon = icon;
  }
}

export class ErrorHandlerMessage<TKey, TEntity> extends ErrorModel {
  id: TKey;
  prop: keyof TEntity;

  constructor(id: TKey, prop: keyof TEntity, message: string) {
    super(message);
    this.id = id;
    this.prop = prop;
  }
}

export class ErrorHandlerField<TEntity> {
  prop: keyof TEntity;
  errorMessage: string;
  condition: Function;

  constructor(prop: keyof TEntity, errorMessage: string, condition?: Function) {
    this.prop = prop;
    this.errorMessage = errorMessage;
    this.condition = condition;
  }
}

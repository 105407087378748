import { BaseComponentProps } from "../../definitions";

export enum GhostableDelay {
  One = 1,
  Two,
  Three,
  Four,
}

export interface GhostableProps extends BaseComponentProps {
  ghosted?: boolean;
  delay: GhostableDelay;
  appear: boolean;
  mountOnEnter: boolean;
  unmountOnExit: boolean;
  onEnter?: Function;
  onEntering?: Function;
  onEntered?: Function;
  onExit?: Function;
  onExiting?: Function;
  onExited?: Function;
}

export enum GhostableClassNames {
  GhostWithTransitionModifier = "nui-ghost--transition",
  GhostWithDoneModifier = "nui-ghost--done",
  SolidWithTransitionModifier = "nui-solid--transition",
  SolidWithDoneModifier = "nui-solid--done",
}

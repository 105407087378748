import { ChangeEvent, FocusEvent } from "react";
import { Theme, BaseComponentProps } from "../../definitions";

export enum SearchTheme {
  Ink = Theme.Ink,
  Steel = Theme.Steel,
  Q4Blue = Theme.Q4Blue,
}

export interface SearchProps extends BaseComponentProps {
  /**
   * Used to paint the component using a specific theme
   */
  theme?: SearchTheme;

  /**
   * Used to supply a value to the component's input element
   */
  value: string;

  /**
   * Placeholder text for the input for when the input is empty
   */
  placeholder?: string;

  /**
   * The maximum character length supplied to the component's input element
   */
  maxLength?: number;

  /**
   * The minimum character length required before onQueryRequest is triggered
   */
  minQueryLength?: number;

  /**
   * The delay between when a character is typed into the input and the triggering of onQueryRequest
   */
  buffer?: number;

  /**
   * Use to determine whether or not the Search should expand when the component's input element is focused
   */
  expandOnFocus?: boolean;

  /**
   * Used to determine whether or not the component is painted visible but inactive
   */
  disabled?: boolean;

  /**
   * Used to supply the component with custom icon classNames
   */
  fieldIcon: string;
  clearIcon: string;

  /**
   * Callbacks triggered during component interaction
   */
  onInputFocus?: (e: FocusEvent) => void;
  onInputBlur?: (e: FocusEvent) => void;
  onInputChange?: (newValue: string, e: ChangeEvent) => void;
  onClear?: () => void;
  onQueryRequest?: (value: string) => void;
}

export enum SearchClassNames {
  Base = "nui-search",
  BaseWithEmptyModifier = "nui-search--empty",
  BaseWithExpandedModifier = "nui-search--expanded",
  BaseWithDisabledModifier = "nui-search--disabled",
  Inner = "nui-search_inner",
  Icon = "nui-search_icon",
  Input = "nui-search_input",
  Clear = "nui-search_clear",
}

export enum SearchIds {
  Input = "Input",
}

import { RefObject } from "react";
import { Theme } from "../../definitions/ui.definition";
import { isEmpty } from "../../utils";

export const getComponentSize = (isSmall: boolean): number => {
  const COMPONENT_SIZE = 40;
  const COMPONENT_SIZE_SMALL = 32;
  return isSmall ? COMPONENT_SIZE_SMALL : COMPONENT_SIZE;
};

export enum DropdownTheme {
  DarkSlate = Theme.DarkSlate,
  Ink = Theme.Ink,
  LightGrey = Theme.LightGrey,
  Steel = Theme.Steel,
  White = Theme.White,
  Spice = Theme.Spice,
}

export interface DropdownState<T> {
  expanded: boolean;
  toggleCount: number;
  filterValue: string;
  filtered: T[];
}

export interface DropdownProps<T> {
  id?: string;
  className?: string;
  customOptionRender?: Function;
  disabled?: boolean;
  dropdownHeight?: number;
  hasFilter: boolean;
  onSelect: Function;
  onToggle?: Function;
  optionKey?: keyof T;
  options: T[];
  placeholder?: string;
  scrollContainerClass?: string;
  small?: boolean;
  theme?: DropdownTheme;
  value: T;
  width?: number | string;
}

class DropdownBaseStyle {
  width: number;
  height: number;
  zIndex: number;
}
class DropdownInnerStyle {
  height: number;
  lineHeight: string;
}
class DropdownPositionStyle {
  top: number;
  left: number;
  width: number;
  height: number;
  marginTop: number;
}
class DropdownToggleStyle {
  transform: string;
}
export class DropdownStyles {
  base: DropdownBaseStyle;
  inner: DropdownInnerStyle;
  position: DropdownPositionStyle;
  toggle: DropdownToggleStyle;

  constructor(width: string | number = 160, dropdownHeight: number, toggleCount: number, componentRef: RefObject<HTMLDivElement>, small: boolean) {
    const height = getComponentSize(small);
    const isRef = !isEmpty(componentRef.current);
    const bounds = isRef ? componentRef.current.getBoundingClientRect() : null;
    Object.assign(this, {
      base: {
        width,
      },
      position: {
        top: isRef ? bounds.top + height : null,
        left: isRef ? bounds.left : null,
        width: isRef ? bounds.width : width,
        height: height + dropdownHeight,
        marginTop: height * -1,
      },
      toggle: {
        transform: `rotate(${toggleCount * 180}deg)`,
      },
    });
  }
}

import React, { memo } from "react";
import { getClassName } from "../../../utils";
import { ToolbarClassNames, ToolbarTitleProps } from "../../../definitions";

function ToolbarTitle({ className, id, dataId, title, annotation }: ToolbarTitleProps): JSX.Element {
  const baseClassName = getClassName(ToolbarClassNames.Title, [
    { condition: !!className, trueClassName: className },
    { condition: !!annotation, trueClassName: ToolbarClassNames.TitleWithAnnotationModifier },
  ]);

  return (
    <h3 className={baseClassName} id={id} data-id={dataId}>
      {title}
      {annotation && <span className={ToolbarClassNames.Annotation}>{annotation}</span>}
    </h3>
  );
}

export default memo(ToolbarTitle);

import { LegacyRef, UIEventHandler } from "react";
import { BaseComponentProps } from "../../definitions";
import { Scrollbars } from "react-custom-scrollbars";

export interface ScrollbarsProps<T> extends BaseComponentProps {
  autoHide?: boolean;
  autoHeight?: boolean;
  darkMode?: boolean;
  hideTracksWhenNotNeeded?: boolean;
  scrollbarRef?: LegacyRef<ScrollbarsRef>;
  onScroll?: UIEventHandler<T>;
  showHorizontalTrack?: boolean;
}

export interface ScrollbarsRef extends Scrollbars {
  container: HTMLElement;
  view: LegacyRef<HTMLElement>;
  trackHorizontal: LegacyRef<HTMLElement>;
  thumbHorizontal: LegacyRef<HTMLElement>;
  trackVertical: LegacyRef<HTMLElement>;
  thumbVertical: LegacyRef<HTMLElement>;
}

export enum ScrollbarsClassNames {
  Base = "nui-scrollbars",
  Thumb = "nui-scrollbars_thumb",
  ThumbWithWhiteModifier = "nui-scrollbars_thumb--white",
  ThumbWithLightModifier = "nui-scrollbars_thumb--light",
  ThumbWithDarkModifier = "nui-scrollbars_thumb--dark",
  Track = "nui-scrollbars_track",
  TrackWithHorizontalModifier = "nui-scrollbars_track--horizontal",
  TrackWithHiddenModifier = "nui-scrollbars_track--hidden",
}

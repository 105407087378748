import { BaseComponentProps } from "../../definitions";

export interface LoadingScreenProps extends Omit<BaseComponentProps, "children"> {
  q4Icon: string;
}

export enum LoadingScreenClassNames {
  Base = "nui-loading-screen",
  Content = "nui-loading-screen_content",
  Logo = "nui-loading-screen_logo",
  LogoLabel = "nui-loading-screen_logo-label",
  Message = "nui-loading-screen_message",
  Spinner = "nui-loading-screen_spinner",
}

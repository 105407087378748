import "./collapsable.component.scss";
import React, { PureComponent } from "react";
import { CollapsableClassNames, CollapsableProps, CollapsableState } from "./collapsable.definition";
import Ghostable from "../ghostable";
import { getClassName } from "../../utils";

// this cannot be converted to a functional component with hooks because accordion uses isCollapsing to block changes
class Collapsable extends PureComponent<CollapsableProps, CollapsableState> {
  private readonly COLLAPSED_HEIGHT = 0;

  constructor(props: CollapsableProps) {
    super(props);

    const initialStatus = props.collapsed;
    this.state = {
      isCollapsing: false,
      isVisible: !initialStatus,
      currentHeight: initialStatus ? this.COLLAPSED_HEIGHT : null,
    };
  }

  componentDidUpdate = (prevProps: CollapsableProps): void => {
    const { collapsed } = this.props;
    const { isVisible } = this.state;

    if (prevProps.collapsed !== collapsed && !isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  };

  handleEnter = (): void => {
    this.setState({
      isCollapsing: true,
      currentHeight: this.COLLAPSED_HEIGHT,
    });
  };

  handleEntering = (node: HTMLElement): void => {
    this.setState({
      currentHeight: node.clientHeight,
    });
  };

  handleEntered = (): void => {
    const { onExpand } = this.props;

    this.setState({
      isCollapsing: false,
      currentHeight: null,
    });

    onExpand && onExpand();
  };

  handleExit = (node: HTMLElement): void => {
    this.setState({
      isCollapsing: true,
      currentHeight: node.clientHeight,
    });
  };

  handleExiting = (): void => {
    this.setState({
      isCollapsing: true,
      currentHeight: this.COLLAPSED_HEIGHT,
    });
  };

  handleExited = (): void => {
    const { onCollapse } = this.props;

    this.setState({
      isCollapsing: false,
      currentHeight: null,
      isVisible: false,
    });
    onCollapse && onCollapse();
  };

  render = (): JSX.Element => {
    const { currentHeight, isCollapsing, isVisible } = this.state;
    const { children, className, id, dataId, collapsed } = this.props;
    return (
      isVisible && (
        <div
          id={id}
          data-id={dataId}
          className={getClassName(CollapsableClassNames.Base, [
            { condition: !!className, trueClassName: className },
            { condition: isCollapsing, trueClassName: CollapsableClassNames.BaseWithCollapsingModifier },
            { condition: collapsed, trueClassName: CollapsableClassNames.BaseWithCollapsedModifier },
          ])}
        >
          <div className={CollapsableClassNames.Content} style={{ height: currentHeight }}>
            <Ghostable
              ghosted={collapsed}
              onEnter={this.handleEnter}
              onEntering={this.handleEntering}
              onEntered={this.handleEntered}
              onExit={this.handleExit}
              onExiting={this.handleExiting}
              onExited={this.handleExited}
            >
              <div className={CollapsableClassNames.OpacityLayer}>{children}</div>
            </Ghostable>
          </div>
        </div>
      )
    );
  };
}

export default Collapsable;

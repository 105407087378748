import "./table.component.scss";
import React, { memo } from "react";
import loadable from "@loadable/component";
const LoadableTable = loadable.lib(() => import("ag-grid-react"));
import type { GridReadyEvent } from "ag-grid-community";
import type { TableProps, AgGridReactLib } from "../../definitions";
import { TableRowDensity, TableClassNames } from "../../definitions";
import { getClassName, isNullOrWhiteSpace } from "../../utils";
import Spinner from "../spinner";
import Swapable from "../swapable";

/**
 * Table Component
 */
function Table(props: TableProps): JSX.Element {
  const DefaultRowDensity = 50;
  const {
    className,
    dataId,
    id,
    loading,
    domLayout,
    sizeToFit,
    rowDensity,
    columnDefs,
    rowData,
    pagination,
    pivotMode,
    pinnedMode,
    onGridReady,
    onRowClicked,
  } = props;

  const baseClassName = getClassName(`${TableClassNames.Base} ag-theme-balham`, [
    { condition: !!className, trueClassName: className },
    { condition: domLayout === "autoHeight", trueClassName: TableClassNames.BaseWithAutoHeightModifier },
    { condition: pagination, trueClassName: TableClassNames.BaseWithPaginationModifier },
    { condition: !columnDefs || !columnDefs.length, trueClassName: TableClassNames.BaseWithNoColumnsModifier },
    { condition: !rowData || !rowData.length, trueClassName: TableClassNames.BaseWithNoDataModifier },
    { condition: !!rowDensity, trueClassName: `${TableClassNames.Base}--${rowDensity.toLowerCase()}` },
    { condition: pivotMode, trueClassName: TableClassNames.BaseWithPivotModeModifier },
    { condition: pinnedMode, trueClassName: TableClassNames.BaseWithPinnedModeModifier },
  ]);

  /**
   * Handle AgGrid's onGridReady event
   * @param grid
   */
  function handleGridReady(grid: GridReadyEvent): void {
    if (!grid) {
      return;
    }

    if (sizeToFit) {
      grid.api.sizeColumnsToFit();
    }

    onGridReady && onGridReady(grid);
  }

  function renderTable({ AgGridReact }: AgGridReactLib): JSX.Element {
    return (
      <AgGridReact
        rowHeight={TableRowDensity[rowDensity] ?? DefaultRowDensity}
        rowClass={onRowClicked && TableClassNames.RowWithOnClickedModifier}
        {...props}
        onGridReady={handleGridReady}
      />
    );
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <Swapable
        selected={+loading}
        layers={[
          <LoadableTable fallback={<Spinner />} key={`table--loaded${isNullOrWhiteSpace(id) ? "" : `-${id}`}`}>
            {renderTable}
          </LoadableTable>,
          <Spinner key={`table--loading${isNullOrWhiteSpace(id) ? "" : `-${id}`}`} />,
        ]}
      />
    </div>
  );
}

Table.defaultProps = {
  domLayout: "normal",
  ensureDomOrder: true,
  sizeToFit: true,
  loading: false,
  headerHeight: TableRowDensity.Default,
  rowDensity: "default",

  rowData: [],
  columnDefs: [],
  defaultColDef: {
    lockPosition: true,
    suppressMovable: true,
    flex: 1,
  },

  overlayNoRowsTemplate: "No data available",
  icons: {
    sortAscending: '<i class="ni-arrow-up-4pt"/>',
    sortDescending: '<i class="ni-arrow-down-4pt"/>',
    groupExpanded: '<i class="ni-caret-sm-up-4pt"/>',
    groupContracted: '<i class="ni-caret-sm-down-4pt"/>',
  },

  sortingOrder: ["asc", "desc"],
  rowSelection: "multiple",

  suppressMultiSort: true,
  suppressRowClickSelection: true,
  suppressCellSelection: true,
  suppressRowTransform: true,
  suppressDragLeaveHidesColumns: true,

  getMainMenuItems: (): [] => [],
  getContextMenuItems: (): [] => [],
};

export default memo(Table);

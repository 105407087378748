import "./toast.component.scss";
import React, { memo } from "react";
import { getClassName } from "../../utils";
import { ToastProps, ToastTheme, ToastStatus, ToastClassNames } from "./toast.definition";

/**
 * Toast Component
 * Should be used in conjunction with react-toastContainer: https://github.com/fkhadra/react-toastify
 */
const Toast = (props: ToastProps): JSX.Element => {
  const { className, id, dataId, theme, type, indicatorIcon, closeIcon, message, closeToast } = props;

  const baseClassName = getClassName(ToastClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${ToastClassNames.Base}--${theme}` },
  ]);
  const indicatorClassName = `${ToastClassNames.Indicator} ${ToastClassNames.Indicator}--${(type || "").toLowerCase()}`;
  const defaultCloseIcon = "ni-close-4pt";

  function getIndicatorIcon(): string {
    if (!!indicatorIcon) return indicatorIcon;

    let defaultIndicatorIcon;

    switch (type) {
      case ToastStatus.Success:
        defaultIndicatorIcon = "ni-checkmark-4pt";
        break;
      case ToastStatus.Info:
      case ToastStatus.Warning:
      case ToastStatus.Error:
      default:
        defaultIndicatorIcon = "ni-warning-4pt";
    }

    return defaultIndicatorIcon;
  }

  function handleCloseClick(): void {
    closeToast && closeToast();
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <div className={indicatorClassName}>
        <i className={getIndicatorIcon()} />
      </div>
      <div className={ToastClassNames.Message}>
        <p>{message}</p>
      </div>
      <i className={`${ToastClassNames.Close} ${closeIcon ?? defaultCloseIcon}`} onClick={handleCloseClick} />
    </div>
  );
};

Toast.defaultProps = {
  theme: ToastTheme.White,
};

export default memo(Toast);

import { ChangeEvent, ReactNode } from "react";
import { BaseComponentProps, Theme } from "../../definitions";

export enum CheckboxTheme {
  Steel = Theme.Steel,
  Teal = Theme.Teal,
}

export enum CheckboxSize {
  Small = "small",
  Default = "default",
}

export interface CheckboxProps extends Omit<BaseComponentProps, "children"> {
  /**
   * A custom id to add to the Checkbox. Required.
   */
  id: string;
  /**
   * The name attribute used in the checkbox input
   */
  name?: string;
  /**
   * Used to paint the component using a specific theme
   */
  theme?: CheckboxTheme;
  /**
   * Used to determine the size of the checkbox
   */
  size: CheckboxSize;
  /**
   * Used to determine checked state for the checkbox input
   */
  checked: boolean;
  /**
   * Used to overwrite checkbox colour while in checked state
   */
  customCheckboxColor?: string;
  /**
   * The default label prop to determine label text or render
   * Note: pass a simple ' ' string for no label rendering
   */
  label?: ReactNode;
  /**
   * Used to paint a label to the right of the checkbox
   * Note: this is a duplicate prop for the sake of better component usability
   */
  labelAlign: "left" | "right";
  /**
   * Used to supply the component with a checkmark icon
   */
  checkmarkIcon?: string;
  /**
   * Used to determine whether or not to render label text as to only render a standalone checkbox
   * Note: If label is not provided, this prop is required. The component will not render otherwise.
   */
  boxOnly?: boolean;
  /**
   * Used to determine whether or not to align the checkbox inline
   */
  inline?: boolean;
  /**
   * Used to disable interaction with the checkbox component
   */
  disabled?: boolean;
  /**
   * Used to determine whether or not to run stopPropagation() on checkbox click events
   * Note: This is really useful when using Checkbox within the Table component
   */
  stopPropagation?: boolean;
  /**
   * A callback function for when the user checks or unchecks the checkbox
   */
  onChange: (checked: boolean, e: ChangeEvent) => void;
}

export enum CheckboxClassNames {
  Base = "nui-checkbox",
  BaseWithCustomColorModifier = "nui-checkbox--custom-color",
  BaseWithCheckedModifier = "nui-checkbox--checked",
  BaseWithBoxOnlyModifier = "nui-checkbox--box-only",
  BaseWithInlineModifier = "nui-checkbox--inline",
  BaseWithDisabledModifier = "nui-checkbox--disabled",
  Label = "nui-checkbox_label",
  Box = "nui-checkbox_box",
  Icon = "nui-checkbox_icon",
  Input = "nui-checkbox_input",
}

export enum CheckboxIds {
  Input = "Input",
}

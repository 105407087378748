import "./toolbar.component.scss";
import React, { memo } from "react";
import { getClassName } from "../../utils";
import { ToolbarClassNames, ToolbarProps, ToolbarTheme } from "../../definitions";
import ToolbarRow from "./row";

function Toolbar({ className, id, dataId, theme, autoRow, autoRowProps, children }: ToolbarProps): JSX.Element {
  const baseClassName = getClassName(ToolbarClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${ToolbarClassNames.Base}--${theme}` },
  ]);

  return (
    <section className={baseClassName} id={id} data-id={dataId}>
      <div className={ToolbarClassNames.Inner}>{autoRow ? <ToolbarRow {...autoRowProps}> {children} </ToolbarRow> : children}</div>
    </section>
  );
}

Toolbar.defaultProps = {
  theme: ToolbarTheme.Rain,
  autoRow: true,
  autoRowProps: {
    justifyContent: "space-between",
  },
};

export default memo(Toolbar);

import "./loadingScreen.component.scss";
import React, { memo } from "react";
import { LoadingScreenClassNames, LoadingScreenProps, SpinnerTheme } from "../../definitions";
import Spinner from "../spinner";
import { getClassName } from "../../utils/ui";

/**
 * LoadingScreen Component
 */
function LoadingScreen({ className, id, dataId, q4Icon }: LoadingScreenProps): JSX.Element {
  const baseClassName = getClassName(LoadingScreenClassNames.Base, [{ condition: !!className, trueClassName: className }]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <div className={LoadingScreenClassNames.Content}>
        <h1 className={LoadingScreenClassNames.Logo}>
          <i className={q4Icon} />
          <span className={LoadingScreenClassNames.LogoLabel}>Q4 Inc.</span>
        </h1>
        <div className={LoadingScreenClassNames.Message}>One moment... Making magic happen.</div>
        <div className={LoadingScreenClassNames.Spinner}>
          <Spinner theme={SpinnerTheme.White} masked={false} />
        </div>
      </div>
    </div>
  );
}

LoadingScreen.defaultProps = {
  q4Icon: "ni-q4-logo",
};

export default memo(LoadingScreen);

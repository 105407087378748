import "./portal.component.scss";
import React, { memo, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { FocusOn } from "react-focus-on";
import { CSSTransition } from "react-transition-group";
import { getClassName } from "../../utils";
import { TransitionSpeed, TransitionSpeedModifier, Opacity, PortalClassNames } from "../../definitions";
import { PortalProps } from "./portal.definition";

function Portal(props: PortalProps): JSX.Element {
  const {
    className,
    id,
    dataId,
    targetElementId,
    targetElementClassName,
    visible,
    position,
    masked,
    maskOpacity,
    transitionSpeed,
    children,
    disableMaskClickToClose,
    disableEscToClose,
    focusOnProps,
    cssTransitionProps,
    onOpen,
    onCloseRequest,
  } = props;

  const componentRef = useRef();
  const portalTarget = document.getElementById(targetElementId);

  // #region Lifecycle Methods
  useEffect(() => {
    if (!portalTarget) {
      return;
    }

    if (visible) {
      !!targetElementClassName && portalTarget.classList.add(targetElementClassName);
      onOpen && onOpen();
    }

    if (!!targetElementClassName && !visible) portalTarget.classList.remove(targetElementClassName);

    return (): void => {
      !!targetElementClassName && portalTarget.classList.remove(targetElementClassName);
    };
  }, [onOpen, portalTarget, targetElementClassName, targetElementId, visible]);
  // #endregion

  if (!portalTarget) {
    return null;
  }

  const baseClassName = getClassName(PortalClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!position, trueClassName: `${PortalClassNames.Base}--${position}` },
    { condition: !!transitionSpeed, trueClassName: `${PortalClassNames.Base}--${transitionSpeed.modifier}-speed` },
  ]);
  const maskClassName = getClassName(PortalClassNames.Mask, [{ condition: !!maskOpacity, trueClassName: `nui-portal_mask--${maskOpacity}` }]);

  function handleFocusEscapeKey(): void {
    !disableEscToClose && visible && onCloseRequest();
  }

  function handleMaskClick(): void {
    !disableMaskClickToClose && onCloseRequest();
  }

  return ReactDOM.createPortal(
    <FocusOn enabled={visible} shards={[componentRef]} {...focusOnProps} onEscapeKey={handleFocusEscapeKey}>
      <CSSTransition
        appear
        in={visible}
        classNames={`${PortalClassNames.Base}-`}
        timeout={transitionSpeed.time}
        mountOnEnter
        unmountOnExit
        {...cssTransitionProps}
      >
        <div ref={componentRef} className={baseClassName} id={id} data-id={dataId}>
          {masked && <div className={maskClassName} onClick={handleMaskClick} />}
          <div className={PortalClassNames.Content}>{children}</div>
        </div>
      </CSSTransition>
    </FocusOn>,
    document.getElementById(targetElementId)
  );
}

Portal.defaultProps = {
  targetElementId: "root",
  visible: false,
  position: "absolute",
  transitionSpeed: new TransitionSpeed(TransitionSpeedModifier.Default),
  masked: true,
  maskOpacity: Opacity.Transparent,
};

export default memo(Portal);

import React, { memo } from "react";
import { getClassName } from "../../../utils";
import { ToolbarClassNames, ToolbarRowProps } from "../../../definitions";

function ToolbarRow({ className, id, dataId, justifyContent, children }: ToolbarRowProps): JSX.Element {
  const baseClassName = getClassName(ToolbarClassNames.Row, [
    { condition: !!className, trueClassName: className },
    { condition: !!justifyContent, trueClassName: `${ToolbarClassNames.Row}--justify-${justifyContent}` },
  ]);

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      {children}
    </div>
  );
}

ToolbarRow.defaultProps = {
  justifyContent: "space-between",
};

export default memo(ToolbarRow);

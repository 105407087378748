import React, { memo } from "react";
import { FormProps, FormClassNames, FormIds, FormFieldProps, FormTheme } from "../../definitions";
import { getClassName, getId } from "../../utils";
import Field from "../field";
import Grid from "../grid";
import GridColumn from "../grid/column";

const Form = (props: FormProps): JSX.Element => {
  const { className, id, dataId, theme, fields } = props;

  const baseClassName = getClassName(FormClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!theme, trueClassName: `${FormClassNames.Base}--${theme}` },
  ]);

  function getSubComponentId(id: string, fieldId: string, suffixId: string, index: number): string {
    return !!fieldId ? getId(fieldId, suffixId) : getId(id, `${suffixId}${index}`);
  }

  function renderField(props: FormFieldProps, index: number): JSX.Element {
    const { key, id: fieldId, ...restProps } = props;

    return (
      <GridColumn key={key} id={getSubComponentId(id, fieldId, FormIds.GridColumn, index)} {...restProps}>
        <Field theme={theme} id={getSubComponentId(id, fieldId, FormIds.Field, index)} {...restProps} />
      </GridColumn>
    );
  }

  return (
    <div className={baseClassName} id={id} data-id={dataId}>
      <Grid>{fields.map((field, index) => renderField(field, index))}</Grid>
    </div>
  );
};

Form.defaultProps = {
  theme: FormTheme.Light,
};

export default memo(Form);
